import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Logo from '../containers/images/logo';
import { useStyle } from '../styles/style';
import ProfileIcon from './ProfileIcon';
import { loginModalOpen } from '../store/actions';
const Header = () => {
    const classes = useStyle();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const handleLoginClick = () => {
        dispatch(loginModalOpen());
    };
    return (React.createElement("div", { className: classes.header },
        React.createElement(Logo, { onClick: () => navigate('/') }),
        (user === null || user === void 0 ? void 0 : user.tenantID) ? React.createElement(ProfileIcon, null) : React.createElement("div", null)));
};
export default Header;
