import { Typography } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import React from 'react';
import { useStyle } from '../../styles/style';
const ResultPieChart = ({ examSet }) => {
    const classes = useStyle();
    return (React.createElement("div", { className: classes.resultPieChart },
        React.createElement(Typography, null, examSet['ExamSet.examSetName']),
        React.createElement(PieChart, { series: [
                {
                    outerRadius: 60,
                    data: [
                        {
                            id: 0,
                            value: examSet.correctAnswers,
                            label: `Correct Answers (${examSet.correctAnswers})`,
                            color: '#42b06e',
                        },
                        {
                            id: 1,
                            value: examSet.wrongAnswers,
                            label: `Wrong Answers (${examSet.wrongAnswers})`,
                            color: '#EC4F4F',
                        },
                        {
                            id: 2,
                            value: examSet.totalQuestions - examSet.attemptedQuestions,
                            label: `Not Attempted (${examSet.totalQuestions - examSet.attemptedQuestions})`,
                            color: '#FFB800',
                        },
                    ],
                },
            ], width: 400, height: 200 }),
        React.createElement("div", { className: classes.resultPCFooter },
            React.createElement(Typography, null,
                "Marks Obtained : ",
                examSet.marksObtained),
            React.createElement(Typography, null,
                "Percentage : ",
                examSet.percentage,
                "%"))));
};
export default ResultPieChart;
