import { FORGOT_PASSWORD_MODAL_CLOSE, FORGOT_PASSWORD_MODAL_OPEN, LOGIN_MODAL_CLOSE, LOGIN_MODAL_OPEN, POST_TEACHER_LOGIN, POST_TEACHER_LOGIN_SUCCESS, POST_TEACHER_SIGN_UP, POST_TEACHER_SIGN_UP_SUCCESS, POST_TENANT_FORGOT_PASSWORD, POST_TENANT_FORGOT_PASSWORD_VERIFY, RESET_PASSWORD_MODAL_OPEN, SIGNUP_MODAL_CLOSE, SIGNUP_MODAL_OPEN, USER_SIGN_OUT, } from './authConstants';
import { GET_ALL_EXAMS, GET_ALL_EXAMS_SUCCESS, GET_ALL_EXAM_SET_DETAILS, GET_BILLING_REPORT, GET_BILLING_REPORT_FAILURE, GET_BILLING_REPORT_SUCCESS, GET_DASHBOARD_REPORT, GET_DASHBOARD_REPORT_FAILURE, GET_DASHBOARD_REPORT_SUCCESS, GET_EXAM_BY_ID, GET_EXAM_REPORT, GET_EXAM_REPORT_FAILURE, GET_EXAM_REPORT_SUCCESS, GET_QUESTIONS_BY_EXAMSET, GET_QUESTIONS_BY_EXAMSET_SUCCESS, GET_QUESTIONS_ON_PAGE_LOAD, GET_STUDENTS_IN_EXAM, GET_STUDENTS_IN_EXAM_FAILURE, GET_STUDENTS_IN_EXAM_SUCCESS, GET_STUDENT_EXAM_RESULT_ANALYSIS, GET_STUDENT_EXAM_RESULT_ANALYSIS_FAILURE, GET_STUDENT_EXAM_RESULT_ANALYSIS_SUCCESS, POST_CONTACT_US_DETAIL, POST_DOWNLOAD_QUESTION_TEMPLATE, POST_EXAM_PUBLISH, POST_EXAM_PUBLISH_SUCCESS, POST_PAGE_AUDIT, POST_SEARCH_EXAM, POST_SEARCH_EXAM_SUCCESS, POST_TOP_LOADER_FALSE, POST_TOP_LOADER_TRUE, POST_UPDATE_EXAM, POST_UPDATE_EXAMSET, POST_UPDATE_EXAMSET_SUCCESS, POST_UPDATE_EXAM_SUCCESS, POST_UPDATE_QUESTION, POST_UPDATE_QUESTION_FAILURE, POST_UPDATE_QUESTION_SUCCESS, SAVE_EXAM_DETAILS, SAVE_EXAM_DETAILS_FAILURE, SAVE_EXAM_DETAILS_SUCCESS, SAVE_EXAM_SET_DETAILS, SAVE_EXAM_SET_DETAILS_SUCCESS, SAVE_QUESTION_DETAILS, SAVE_QUESTION_DETAILS_SUCCESS, SET_SNACKBAR, UPDATE_LOADER, GET_STUDENT_TENANT_MAPPING, GET_STUDENT_TENANT_MAPPING_FAILURE, GET_STUDENT_TENANT_MAPPING_SUCCESS, POST_STUDENT_STATUS, } from './constants';
export const getAllExams = () => ({
    type: GET_ALL_EXAMS,
});
export const getAllExamsSuccess = (payload) => ({
    type: GET_ALL_EXAMS_SUCCESS,
    payload,
});
export const saveExamDetails = (action) => ({
    type: SAVE_EXAM_DETAILS,
    payload: action,
});
export const saveExamDetailsSuccess = (payload) => ({
    type: SAVE_EXAM_DETAILS_SUCCESS,
    payload,
});
export const saveExamDetailsFailure = () => ({
    type: SAVE_EXAM_DETAILS_FAILURE,
});
export const getExamById = (payload) => ({
    type: GET_EXAM_BY_ID,
    payload,
});
export const setSnackbar = (payload) => ({
    type: SET_SNACKBAR,
    payload,
});
export const saveExamSetDetails = (payload) => ({
    type: SAVE_EXAM_SET_DETAILS,
    payload,
});
export const saveExamSetDetailsSuccess = (payload) => ({
    type: SAVE_EXAM_SET_DETAILS_SUCCESS,
    payload,
});
export const getAllExamSetDetails = (payload) => ({
    type: GET_ALL_EXAM_SET_DETAILS,
    payload,
});
export const saveQuestionDetails = (payload) => ({
    type: SAVE_QUESTION_DETAILS,
    payload,
});
export const saveQuestionDetailsSuccess = (payload) => ({
    type: SAVE_QUESTION_DETAILS_SUCCESS,
    payload,
});
export const getQuestionsByExamSet = (payload) => ({
    type: GET_QUESTIONS_BY_EXAMSET,
    payload,
});
export const getQuestionsByExamSetSuccess = (payload) => ({
    type: GET_QUESTIONS_BY_EXAMSET_SUCCESS,
    payload,
});
export const getQuestionPageLoad = (payload) => ({
    type: GET_QUESTIONS_ON_PAGE_LOAD,
    payload,
});
export const updateLoader = (payload) => ({
    type: UPDATE_LOADER,
    payload,
});
export const postExamPublish = (payload) => ({
    type: POST_EXAM_PUBLISH,
    payload,
});
export const postExamPublishSuccess = (payload) => ({
    type: POST_EXAM_PUBLISH_SUCCESS,
    payload,
});
export const postTeacherLoginSuccess = (payload) => ({
    type: POST_TEACHER_LOGIN_SUCCESS,
    payload,
});
export const userSignOut = () => ({
    type: USER_SIGN_OUT,
});
export const resetPasswordModalOpen = () => ({
    type: RESET_PASSWORD_MODAL_OPEN,
});
export const postUpdateExam = (payload) => ({
    type: POST_UPDATE_EXAM,
    payload,
});
export const postUpdateExamSuccess = (payload) => ({
    type: POST_UPDATE_EXAM_SUCCESS,
    payload,
});
export const postSearchExam = (payload) => ({
    type: POST_SEARCH_EXAM,
    payload,
});
export const postSearchExamSuccess = (payload) => ({
    type: POST_SEARCH_EXAM_SUCCESS,
    payload,
});
export const postUpdateExamset = (payload) => ({
    type: POST_UPDATE_EXAMSET,
    payload,
});
export const postUpdateExamsetSuccess = (payload) => ({
    type: POST_UPDATE_EXAMSET_SUCCESS,
    payload,
});
export const getExamReport = () => ({
    type: GET_EXAM_REPORT,
});
export const getExamReportSuccess = (payload) => ({
    type: GET_EXAM_REPORT_SUCCESS,
    payload,
});
export const getExamReportFailure = () => ({
    type: GET_EXAM_REPORT_FAILURE,
});
export const getDashboardReport = () => ({
    type: GET_DASHBOARD_REPORT,
});
export const getDashboardReportSuccess = (payload) => ({
    type: GET_DASHBOARD_REPORT_SUCCESS,
    payload,
});
export const getDashboardReportFailure = () => ({
    type: GET_DASHBOARD_REPORT_FAILURE,
});
export const getBillingReport = () => ({
    type: GET_BILLING_REPORT,
});
export const getBillingReportSuccess = (payload) => ({
    type: GET_BILLING_REPORT_SUCCESS,
    payload,
});
export const getBillingReportFailure = () => ({
    type: GET_BILLING_REPORT_FAILURE,
});
export const getStudentsInExam = (payload) => ({
    type: GET_STUDENTS_IN_EXAM,
    payload,
});
export const getStudentsInExamSuccess = (payload) => ({
    type: GET_STUDENTS_IN_EXAM_SUCCESS,
    payload,
});
export const getStudentsInExamFailure = () => ({
    type: GET_STUDENTS_IN_EXAM_FAILURE,
});
export const getStudentExamResultAnalysis = (payload) => ({
    type: GET_STUDENT_EXAM_RESULT_ANALYSIS,
    payload,
});
export const getStudentExamResultAnalysisSuccess = (payload) => ({
    type: GET_STUDENT_EXAM_RESULT_ANALYSIS_SUCCESS,
    payload,
});
export const getStudentExamResultAnalysisFailure = (payload) => ({
    type: GET_STUDENT_EXAM_RESULT_ANALYSIS_FAILURE,
    payload,
});
export const postUpdateQuestion = (payload) => ({
    type: POST_UPDATE_QUESTION,
    payload,
});
export const postUpdateQuestionSuccess = (payload) => ({
    type: POST_UPDATE_QUESTION_SUCCESS,
    payload,
});
export const postUpdateQuestionFailure = () => ({
    type: POST_UPDATE_QUESTION_FAILURE,
});
export const postContactUsDetail = (payload) => ({
    type: POST_CONTACT_US_DETAIL,
    payload,
});
export const loginModalOpen = () => ({ type: LOGIN_MODAL_OPEN });
export const postTeacherSignUp = (payload) => ({
    type: POST_TEACHER_SIGN_UP,
    payload,
});
export const postTeacherSignUpSuccess = () => ({
    type: POST_TEACHER_SIGN_UP_SUCCESS,
});
export const forgotPasswordModalOpen = () => ({
    type: FORGOT_PASSWORD_MODAL_OPEN,
});
export const forgotPasswordModalClose = () => ({
    type: FORGOT_PASSWORD_MODAL_CLOSE,
});
export const loginModalClose = () => ({ type: LOGIN_MODAL_CLOSE });
export const signUpModalClose = () => ({ type: SIGNUP_MODAL_CLOSE });
export const signUpModalOpen = () => ({ type: SIGNUP_MODAL_OPEN });
export const postTeacherLogin = (payload) => ({
    type: POST_TEACHER_LOGIN,
    payload,
});
export const postPageAudit = (payload) => ({
    type: POST_PAGE_AUDIT,
    payload,
});
export const postTenantForgotPassword = (payload) => ({
    type: POST_TENANT_FORGOT_PASSWORD,
    payload,
});
export const postTenantForgotPasswordVerify = (payload) => ({
    type: POST_TENANT_FORGOT_PASSWORD_VERIFY,
    payload,
});
export const postTopLoaderTrue = () => ({
    type: POST_TOP_LOADER_TRUE,
});
export const postTopLoaderFalse = () => ({
    type: POST_TOP_LOADER_FALSE,
});
export const postDownloadQuestionTemplate = () => ({
    type: POST_DOWNLOAD_QUESTION_TEMPLATE,
});
export const getStudentTenantMapping = () => ({
    type: GET_STUDENT_TENANT_MAPPING,
});
export const getStudentTenantMappingSuccess = (payload) => ({
    type: GET_STUDENT_TENANT_MAPPING_SUCCESS,
    payload,
});
export const getStudentTenantMappingFailure = () => ({
    type: GET_STUDENT_TENANT_MAPPING_FAILURE,
});
export const postStudentStatus = (payload) => ({
    type: POST_STUDENT_STATUS,
    payload,
});
