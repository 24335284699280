import { BookmarkAdded } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import LandingPageImage from '../../containers/images/LandingPageImage';
import { SliderImages } from '../../containers/images/SliderImages';
import { loginModalOpen, postPageAudit, signUpModalOpen, } from '../../store/actions';
import { PRIMARY_COLOR } from '../../styles/colors';
import { useStyle } from '../../styles/style';
const images = [
    {
        url: SliderImages[0],
        alt: '2',
    },
    {
        url: SliderImages[1],
        alt: '1',
    },
    {
        url: SliderImages[2],
        alt: '3',
    },
    {
        url: SliderImages[3],
        alt: '4',
    },
    {
        url: SliderImages[4],
        alt: '5',
    },
];
const LandingMain = () => {
    const classes = useStyle();
    const dispatch = useDispatch();
    return (React.createElement("div", { className: classes.landingPageMain },
        React.createElement("div", { className: classes.landingPageTopFlex },
            React.createElement(Stack, { className: classes.landingPageData, spacing: 2 },
                React.createElement(Typography, { variant: "h1", style: { display: 'flex' }, color: PRIMARY_COLOR.GREY150, className: classes.landingPageTitle },
                    React.createElement(Typography, { variant: "h1", style: { color: PRIMARY_COLOR.PRIMARY }, className: classes.landingPageTitle }, "H"),
                    "ey Teacher !!!"),
                React.createElement(Typography, { variant: "h4", color: PRIMARY_COLOR.GREY125 }, "Earn for Lifetime \u2013 Your Questions, Your Profits!"),
                React.createElement(Stack, { spacing: 1, direction: "row", alignItems: "center" },
                    React.createElement(BookmarkAdded, { style: {
                            fill: PRIMARY_COLOR.PRIMARY,
                            color: PRIMARY_COLOR.PRIMARY,
                        } }),
                    React.createElement(Typography, { variant: "h6" }, "Earn life long income with one time effort.")),
                React.createElement(Stack, { spacing: 1, direction: "row", alignItems: "center" },
                    React.createElement(BookmarkAdded, { style: {
                            fill: PRIMARY_COLOR.PRIMARY,
                            color: PRIMARY_COLOR.PRIMARY,
                        } }),
                    React.createElement(Typography, { variant: "h6" }, "Create an unlimited number of mock tests without any cost.")),
                React.createElement(Stack, { spacing: 1, direction: "row", alignItems: "center" },
                    React.createElement(BookmarkAdded, { style: {
                            fill: PRIMARY_COLOR.PRIMARY,
                            color: PRIMARY_COLOR.PRIMARY,
                        } }),
                    React.createElement(Typography, { variant: "h6" }, "Maximize Earnings: Up to 90% per Student in Your Mock Test!")),
                React.createElement(Stack, { spacing: 1, direction: "row", alignItems: "center" },
                    React.createElement(BookmarkAdded, { style: {
                            fill: PRIMARY_COLOR.PRIMARY,
                            color: PRIMARY_COLOR.PRIMARY,
                        } }),
                    React.createElement(Typography, { variant: "h6" }, "Tailor Your Preferences: Sets, Duration, Fees \u2013 Your Exam, Your Way.")),
                React.createElement(Stack, { spacing: 1, direction: "row", alignItems: "center" },
                    React.createElement(BookmarkAdded, { style: {
                            fill: PRIMARY_COLOR.PRIMARY,
                            color: PRIMARY_COLOR.PRIMARY,
                        } }),
                    React.createElement(Typography, { variant: "h6" }, "Teacher-Student Nexus: Seamless Learning, Infinite\u00A0Impact.")),
                React.createElement(Stack, { direction: "row", spacing: 5 },
                    React.createElement(Button, { variant: "contained", disableElevation: true, size: "large", className: classes.loginButton, style: {
                            fontWeight: '600',
                        }, onClick: () => {
                            dispatch(postPageAudit({ page: 'TEACHER PORTAL:SignUp Modal Open' }));
                            dispatch(signUpModalOpen());
                        } }, "Sign Up"),
                    React.createElement(Button, { variant: "outlined", size: "large", className: classes.loginButton, style: {
                            fontWeight: '600',
                        }, onClick: () => {
                            dispatch(postPageAudit({ page: 'TEACHER PORTAL:Login Modal Open' }));
                            dispatch(loginModalOpen());
                        } },
                        ' ',
                        "Login"))),
            React.createElement("div", null,
                React.createElement(LandingPageImage, null))),
        React.createElement("div", { style: {
                background: 'grey',
                color: 'white',
                marginBottom: '2rem',
                height: '5rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            } },
            React.createElement(Typography, { variant: "h4" }, "100+ Teachers Registered ")),
        React.createElement("div", { className: classes.landingPageVideoDiv },
            React.createElement("iframe", { height: "315", allowFullScreen: true, className: classes.landingPageVideo, src: "https://www.youtube.com/embed/QqtMhvlkUiw?si=_u_dFm_xY2jA4s-g", title: "YouTube video player", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" }),
            React.createElement("img", { src: SliderImages[1], alt: "1", className: classes.landingPageTimelineImg, height: "315" }))));
};
export default LandingMain;
