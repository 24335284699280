import React from 'react';
import { Typography } from '@mui/material';
import { useStyle } from '../../styles/style';
import { DASHBOARD_REPORT } from '../../containers/constants';
const DashboardTile = ({ report, value }) => {
    const classes = useStyle();
    return (React.createElement("div", { className: classes.dashboardBox },
        React.createElement(Typography, { variant: "h6" },
            value,
            " (",
            DASHBOARD_REPORT[value],
            ")"),
        React.createElement("div", null, Object.keys(report).map((key) => (React.createElement("div", { className: classes.alignText, key: key },
            React.createElement(Typography, { variant: "overline" },
                key.split('_').join(' '),
                " :",
                ' '),
            React.createElement(Typography, null,
                " ",
                report[key])))))));
};
export default DashboardTile;
