import { ThemeProvider } from '@mui/material';
import { TourProvider } from '@reactour/tour';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import store from './store/configureStore';
import { theme } from './styles/theme';
import { steps } from './containers/tour/steps';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(React.createElement(React.StrictMode, null,
    React.createElement(TourProvider, { steps: steps, showDots: false, showBadge: false },
        React.createElement(Provider, { store: store },
            React.createElement(ThemeProvider, { theme: theme },
                React.createElement(App, null))))));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
