import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { useSelector } from 'react-redux';
const BillingReport = () => {
    const loader = useSelector((state) => state.exam.loader);
    const examReport = useSelector((state) => state.exam.billingReport);
    const columns = [
        { field: 'id' },
        { field: 'examName', headerName: 'Exam', width: 150 },
        { field: 'month', headerName: 'Month', width: 240 },
        { field: 'year', headerName: 'Year', width: 240 },
        {
            field: 'student_enrolled',
            headerName: 'Student Enrolled',
            width: 180,
        },
        {
            field: 'amount_paid',
            headerName: 'Amount Paid',
            width: 240,
        },
        {
            field: 'amount_pending',
            headerName: 'Amount Pending',
            width: 240,
        },
    ];
    const rows = examReport === null || examReport === void 0 ? void 0 : examReport.map((exam) => {
        const e = {
            id: exam['ExamMaster.examID'],
            examName: exam['ExamMaster.examName'],
            month: exam['ExamMaster.MONTH'],
            year: exam['ExamMaster.YEAR'],
            student_enrolled: exam.StudentCount,
            amount_paid: 0,
            amount_pending: exam.StudentCount * exam['ExamMaster.totalExamPrice'],
        };
        return e;
    });
    return (React.createElement("div", { style: { height: 600, width: '100%' } },
        React.createElement(DataGrid, { rows: rows, columns: columns, loading: loader.billingReport, columnVisibilityModel: {
                id: false,
            }, disableColumnMenu: true })));
};
export default BillingReport;
