import { Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Logo from '../containers/images/logo';
import { useStyle } from '../styles/style';
import { postPageAudit } from '../store/actions';
const NewFooter = () => {
    const classes = useStyle();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    return (React.createElement("div", { className: classes.footer },
        React.createElement("div", { className: classes.footerContent },
            React.createElement(Typography, { className: clsx(classes.typo, classes.pointer), onClick: () => {
                    dispatch(postPageAudit({ page: 'TEACHER PORTAL:ContactUs Page Open' }));
                    navigate('/contactUs');
                } }, "Contact Us"),
            React.createElement(Typography, { className: clsx(classes.typo, classes.pointer), onClick: () => {
                    dispatch(postPageAudit({
                        page: 'TEACHER PORTAL:Help & Support Page Open',
                    }));
                    navigate('/contactUs');
                } }, "Help and Support"),
            React.createElement(Typography, { className: clsx(classes.typo, classes.pointer), onClick: () => {
                    dispatch(postPageAudit({
                        page: 'TEACHER PORTAL:Term & Condition Page Open',
                    }));
                    navigate('/Term-Condition.html');
                } }, "Terms n Conditions"),
            React.createElement(Typography, { className: clsx(classes.typo, classes.pointer), onClick: () => {
                    dispatch(postPageAudit({
                        page: 'TEACHER PORTAL:Privacy Policy Page Open',
                    }));
                    navigate('/Privacy-Policy.html');
                } }, "Privacy Policy")),
        React.createElement("div", { className: classes.divider }),
        React.createElement("div", { className: classes.footerLogo },
            React.createElement(Logo, { onClick: () => navigate('/') }),
            React.createElement("div", null,
                React.createElement(Typography, { className: classes.typo }, "Copyright 2024 Zonatech Digital Private Limited"),
                React.createElement(Typography, { className: classes.typo }, "Mail:info@zonatechdigital.com")))));
};
export default NewFooter;
