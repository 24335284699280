/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { zodResolver } from '@hookform/resolvers/zod';
import { Close, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Checkbox, IconButton, InputAdornment, Modal, Stack, TextField, Typography, } from '@mui/material';
import { clsx } from 'clsx';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { loginModalOpen, postPageAudit, postTeacherSignUp, setSnackbar, signUpModalClose, } from '../../store/actions';
import { useStyle } from '../../styles/style';
const SignUp = () => {
    var _a, _b, _c, _d, _e;
    const dispatch = useDispatch();
    const classes = useStyle();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [checkbox, setCheckbox] = useState(false);
    const { signUpModal } = useSelector((state) => state.auth.modals);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleClose = () => {
        dispatch(postPageAudit({ page: 'TEACHER PORTAL:SignUp Modal Close' }));
        dispatch(signUpModalClose());
    };
    const handleSignUp = (data) => {
        if (!checkbox) {
            dispatch(setSnackbar({
                snackbarOpen: true,
                snackbarType: 'warning',
                snackbarMsg: 'Please check Terms and Conditions.',
            }));
        }
        else {
            dispatch(postPageAudit({ page: 'TEACHER PORTAL:SignUp Button Click' }));
            dispatch(postTeacherSignUp({
                activeStatus: true,
                createdBy: data.emailID,
                modifiedBy: data.emailID,
                createdDate: new Date(),
                modifiedDate: new Date(),
                tenantID: '',
                tenantName: `${data.firstName} ${data.lastName}`,
                contactPerson: data.firstName,
                contactNumber: data.contactNumber,
                emailId: data.emailID,
                address: '',
                password: data.password,
            }));
        }
    };
    const handleLoginClick = () => {
        dispatch(loginModalOpen());
    };
    const phoneRegex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$|^(?=\s*$)/);
    const passwordRegex = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#?!@$%^&*-]).{6,}$/);
    const formValidation = z.object({
        firstName: z.string().min(3).nonempty('First Name is required.'),
        lastName: z.string().nonempty('Last Name is required.'),
        emailID: z.string().email().nonempty('Email is required.'),
        contactNumber: z.string().regex(phoneRegex, 'Invalid Number!'),
        password: z
            .string()
            .nonempty('Password is required.')
            .min(6, 'Password should be of minimum 6 characters.')
            .regex(passwordRegex, 'Password should match following criteria.'),
    });
    const loginForm = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            emailID: '',
            contactNumber: '',
            password: '',
        },
        resolver: zodResolver(formValidation),
        mode: 'all',
    });
    const { register, handleSubmit, formState: { errors }, reset, } = loginForm;
    useEffect(() => {
        reset();
    }, []);
    return (signUpModal && (React.createElement(Modal, { open: signUpModal, onClose: handleClose, disableScrollLock: true },
        React.createElement(Box, { className: classes.loginModal },
            React.createElement("div", { className: classes.closeIconBody },
                React.createElement(Typography, { variant: "h3", fontWeight: "400" }, "Sign Up"),
                React.createElement(IconButton, { onClick: handleClose, size: "small" },
                    React.createElement(Close, null))),
            React.createElement("div", { className: classes.loginBody },
                React.createElement(Stack, { spacing: 2, direction: "row" },
                    React.createElement(TextField, Object.assign({ label: "First Name*", variant: "outlined", size: "small", fullWidth: true }, register('firstName'), { error: !!errors.firstName, helperText: (_a = errors.firstName) === null || _a === void 0 ? void 0 : _a.message })),
                    React.createElement(TextField, Object.assign({ label: "Last Name*", variant: "outlined", size: "small", fullWidth: true }, register('lastName'), { error: !!errors.lastName, helperText: (_b = errors.lastName) === null || _b === void 0 ? void 0 : _b.message }))),
                React.createElement(TextField, Object.assign({ label: "Email*", variant: "outlined", type: "email", size: "small", fullWidth: true }, register('emailID'), { error: !!errors.emailID, helperText: (_c = errors.emailID) === null || _c === void 0 ? void 0 : _c.message })),
                React.createElement(TextField, Object.assign({ variant: "outlined", label: "Phone Number", type: "phoneNo", size: "small", fullWidth: true }, register('contactNumber'), { error: !!errors.contactNumber, helperText: (_d = errors.contactNumber) === null || _d === void 0 ? void 0 : _d.message })),
                React.createElement(TextField, Object.assign({ variant: "outlined", label: "Password*", type: showPassword ? 'text' : 'password', size: "small", InputProps: {
                        endAdornment: (React.createElement(InputAdornment, { position: "end" },
                            React.createElement(IconButton, { "aria-label": "toggle password visibility", onClick: () => setShowPassword((prev) => !prev), onMouseDown: handleMouseDownPassword, edge: "end" }, showPassword ? React.createElement(VisibilityOff, null) : React.createElement(Visibility, null)))),
                    }, fullWidth: true }, register('password'), { error: !!errors.password, helperText: (_e = errors.password) === null || _e === void 0 ? void 0 : _e.message })),
                errors.password ? (React.createElement("ul", { className: classes.passwordList },
                    React.createElement("li", null, "Should have minimum 6 characters."),
                    React.createElement("li", null, "Should have both capital and small alphabet."),
                    React.createElement("li", null, "Should have a numeric character."),
                    React.createElement("li", null, "Should have minimum 1 special character (#?!@$%^&*-)."))) : (React.createElement(React.Fragment, null)),
                React.createElement("div", null,
                    React.createElement(Checkbox, { size: "small", disableRipple: true, checked: checkbox, onChange: (e) => setCheckbox(e.target.checked) }),
                    React.createElement(Typography, { variant: "caption" },
                        "By Signing up, you confirm our",
                        ' ',
                        React.createElement(Typography, { variant: "caption", className: clsx(classes.blue, classes.pointer), onClick: () => {
                                navigate(`/Term-Condition.html`);
                            } }, "Terms of Condition"))),
                React.createElement(Button, { variant: "contained", fullWidth: true, onClick: handleSubmit(handleSignUp) }, "SIGNUP"),
                React.createElement("div", { className: classes.rowAlign },
                    React.createElement(Typography, null, "Already Have an Account?"),
                    React.createElement(Button, { onClick: handleLoginClick }, "Login")))))));
};
export default SignUp;
