import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import saga from '../saga';
import reducer from './reducer';
const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
    reducer,
    middleware: () => [sagaMiddleware],
    devTools: process.env.name !== 'production',
});
sagaMiddleware.run(saga);
export default store;
