/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from '../../UIElements/Icons/Loader';
import { useStyle } from '../../styles/style';
import { useGetExamById } from '../../utils/hookUtils';
import ExamSetDescriptionForm from './ExamSetDescriptionForm';
import ExamSetHeader from './ExamSetHeader';
import * as Actions from '../../store/actions';
const ExamSetDescription = () => {
    const { examID } = useParams();
    const classes = useStyle();
    const dispatch = useDispatch();
    if (!examID)
        throw Error('ExamID not found');
    const exam = useGetExamById(examID);
    const examSets = useSelector((state) => state.exam.examSet);
    const { examSetDescriptionLoader } = useSelector((state) => state.exam.loader);
    const examSet = examSets === null || examSets === void 0 ? void 0 : examSets[examID];
    const { noOfSets, examName } = exam;
    if (!noOfSets) {
        dispatch(Actions.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: 'No sets found for exam.Please modify the exam to create set.',
        }));
        throw new Error('No Exam Set found');
    }
    const [access, setAccess] = useState(true);
    const [defaultValues, setDefaultValues] = useState({
        sets: Array(noOfSets).fill({ examSetName: '', examSetID: '' }),
    });
    useEffect(() => {
        if (exam.publishStatus)
            setAccess(false);
        else
            setAccess(true);
        if (examSet === null || examSet === void 0 ? void 0 : examSet.length)
            setDefaultValues((prevValues) => ({
                sets: prevValues.sets.map((set, index) => {
                    var _a, _b, _c, _d;
                    return ({
                        examSetName: (_b = (_a = examSet === null || examSet === void 0 ? void 0 : examSet[index]) === null || _a === void 0 ? void 0 : _a.examSetName) !== null && _b !== void 0 ? _b : '',
                        examSetID: (_d = (_c = examSet === null || examSet === void 0 ? void 0 : examSet[index]) === null || _c === void 0 ? void 0 : _c.examSetID) !== null && _d !== void 0 ? _d : '',
                    });
                }),
            }));
    }, [examSet, examID]);
    useEffect(() => {
        setDefaultValues({
            sets: Array(noOfSets)
                .fill({ examSetName: '', examSetID: '' })
                .map((set, index) => {
                var _a, _b, _c, _d;
                return ({
                    examSetName: (_b = (_a = examSet === null || examSet === void 0 ? void 0 : examSet[index]) === null || _a === void 0 ? void 0 : _a.examSetName) !== null && _b !== void 0 ? _b : '',
                    examSetID: (_d = (_c = examSet === null || examSet === void 0 ? void 0 : examSet[index]) === null || _c === void 0 ? void 0 : _c.examSetID) !== null && _d !== void 0 ? _d : '',
                });
            }),
        });
    }, [examID]);
    return examSetDescriptionLoader ? (React.createElement(Loader, { loaderClass: classes.loader, loaderDivClass: classes.setLoaderDiv })) : (React.createElement("div", { className: classes.examFormBox, id: "examForm" },
        React.createElement(ExamSetHeader, { examName: examName }),
        React.createElement(ExamSetDescriptionForm, { defaultValues: defaultValues, access: access, examID: examID, modify: examSet === null || examSet === void 0 ? void 0 : examSet.length })));
};
export default ExamSetDescription;
