import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const ExamReport = () => {
    const navigate = useNavigate();
    const loader = useSelector((state) => state.exam.loader);
    const examReport = useSelector((state) => state.exam.examReport);
    const columns = [
        { field: 'id' },
        { field: 'examName', headerName: 'Exam', width: 150 },
        {
            field: 'studentEnrolled',
            headerName: 'No Of Student Enrolled',
            width: 240,
        },
        {
            field: 'studentAppeared',
            headerName: 'No Of Student Appeared',
            width: 240,
        },
        {
            field: 'averageScore',
            headerName: 'Average Score',
            width: 180,
        },
        {
            field: 'publishedDate',
            headerName: 'Exam Published Date',
            width: 240,
        },
        {
            field: 'amountEarned',
            headerName: 'Total amount earned',
            width: 240,
        },
    ];
    const rows = Object.keys(examReport).map((examID) => {
        var _a;
        const exam = examReport[examID];
        const e = {
            id: examID,
            examName: exam.examName,
            studentEnrolled: exam['StudentExamMappings.StudentCount'],
            studentAppeared: exam.studentAppeared,
            averageScore: (_a = exam.averageScore) !== null && _a !== void 0 ? _a : 0,
            publishedDate: new Date(exam.modifiedDate).toLocaleDateString(),
            amountEarned: exam['StudentExamMappings.StudentCount'] * exam.totalExamPrice,
        };
        return e;
    });
    const handleOnCellClick = (params) => {
        if (params.field === 'examName')
            navigate(`/exams/${params.id}/view`);
        if (params.field === 'studentEnrolled')
            navigate(`/${params.id}/students`);
    };
    return (React.createElement("div", { style: { height: 600, width: '100%' } },
        React.createElement(DataGrid, { rows: rows, columns: columns, loading: loader.examReport, columnVisibilityModel: {
                id: false,
            }, disableColumnMenu: true, onCellClick: handleOnCellClick })));
};
export default ExamReport;
