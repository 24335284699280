/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector } from 'react-redux';
export const useGetExamById = (examID) => {
    var _a;
    const exams = useSelector((state) => state.exam.exams);
    const exam = (_a = exams.filter((e) => e.examID === examID)) === null || _a === void 0 ? void 0 : _a[0];
    if (!exam)
        throw Error('Exam Not Found');
    return exam;
};
export const useGetExamSetByExamId = (examID) => {
    const examSets = useSelector((state) => state.exam.examSet);
    const examSet = examSets === null || examSets === void 0 ? void 0 : examSets[examID];
    if (!examSet)
        throw Error('Exam Sets not found');
    return examSet;
};
export const useQuestionsByExamSetId = (examSetID) => {
    const questions = useSelector((state) => state.exam.questions);
    const question = questions === null || questions === void 0 ? void 0 : questions[examSetID];
    return question;
};
export const useGetLoggedInUser = () => {
    const user = useSelector((state) => state.auth.user);
    return user;
};
