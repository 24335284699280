import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Button } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { postDownloadQuestionTemplate } from '../../store/actions';
const MCQQuestionTemplateButton = () => {
    const dispatch = useDispatch();
    const handleDownload = () => {
        dispatch(postDownloadQuestionTemplate());
    };
    return (React.createElement(Button, { onClick: handleDownload },
        React.createElement(CloudDownloadIcon, null),
        "Download Template"));
};
export default MCQQuestionTemplateButton;
