import React from 'react';
import ExamForm from '.';
const AddExam = () => {
    const defaultValues = {
        examName: '',
        examDescription: '',
        disclosure: '',
        totalExamPrice: null,
        noOfQuestionsPerSet: null,
        correctAnswerMark: null,
        negativeAnswerMark: null,
        durationInMinute: null,
        noOfSets: null,
        negativeMarkingAllowed: 'NO',
    };
    return React.createElement(ExamForm, { values: defaultValues, view: false, update: false });
};
export default AddExam;
