/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useSelector } from 'react-redux';
import Loader from '../../UIElements/Icons/Loader';
import { useStyle } from '../../styles/style';
const TopLoader = () => {
    const { screenLoader } = useSelector((state) => state.exam.loader);
    const classes = useStyle();
    return screenLoader ? (React.createElement("div", { className: classes.curtain },
        React.createElement(Loader, { loaderClass: classes.loader }))) : (React.createElement("div", null));
};
export default TopLoader;
