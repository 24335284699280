import produce from 'immer';
import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { GET_ALL_EXAMS, GET_ALL_EXAMS_SUCCESS, GET_ALL_EXAM_SET_DETAILS, GET_BILLING_REPORT, GET_BILLING_REPORT_SUCCESS, GET_DASHBOARD_REPORT, GET_DASHBOARD_REPORT_SUCCESS, GET_EXAM_REPORT, GET_EXAM_REPORT_SUCCESS, GET_QUESTIONS_BY_EXAMSET, GET_QUESTIONS_BY_EXAMSET_SUCCESS, GET_QUESTIONS_ON_PAGE_LOAD, GET_STUDENTS_IN_EXAM, GET_STUDENTS_IN_EXAM_SUCCESS, GET_STUDENT_EXAM_RESULT_ANALYSIS, GET_STUDENT_EXAM_RESULT_ANALYSIS_SUCCESS, GET_STUDENT_TENANT_MAPPING, GET_STUDENT_TENANT_MAPPING_FAILURE, GET_STUDENT_TENANT_MAPPING_SUCCESS, POST_EXAM_PUBLISH_SUCCESS, POST_SEARCH_EXAM, POST_SEARCH_EXAM_SUCCESS, POST_TOP_LOADER_FALSE, POST_TOP_LOADER_TRUE, POST_UPDATE_EXAM, POST_UPDATE_EXAMSET, POST_UPDATE_EXAMSET_SUCCESS, POST_UPDATE_EXAM_SUCCESS, POST_UPDATE_QUESTION, POST_UPDATE_QUESTION_FAILURE, POST_UPDATE_QUESTION_SUCCESS, SAVE_EXAM_DETAILS, SAVE_EXAM_DETAILS_FAILURE, SAVE_EXAM_DETAILS_SUCCESS, SAVE_EXAM_SET_DETAILS, SAVE_EXAM_SET_DETAILS_SUCCESS, SAVE_QUESTION_DETAILS, SAVE_QUESTION_DETAILS_SUCCESS, SET_SNACKBAR, UPDATE_LOADER, } from './constants';
const initialState = {
    exams: [],
    examSet: {},
    questions: {},
    loader: { studentTenantMapping: false },
    snackbar: { snackbarOpen: false, snackbarType: 'success', snackbarMsg: '' },
    searchExams: [],
    examReport: {},
    dashboardReport: {},
    billingReport: [],
    studentsInExam: {},
    studentExamResultAnalysis: {},
    studentTenantMapping: [],
};
// eslint-disable-next-line @typescript-eslint/default-param-last
const examReducer = (state = initialState, action) => produce(state, () => {
    var _a, _b, _c;
    switch (action.type) {
        case GET_ALL_EXAMS: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { examListLoader: true }) });
        }
        case GET_ALL_EXAMS_SUCCESS: {
            return Object.assign(Object.assign({}, state), { exams: action.payload, loader: Object.assign(Object.assign({}, state.loader), { examListLoader: false }), searchExams: [] });
        }
        case SAVE_EXAM_DETAILS: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { screenLoader: true }) });
        }
        case SAVE_EXAM_DETAILS_SUCCESS: {
            return Object.assign(Object.assign({}, state), { exams: [...state.exams, action.payload], loader: Object.assign(Object.assign({}, state.loader), { screenLoader: false }) });
        }
        case SAVE_EXAM_DETAILS_FAILURE: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { screenLoader: false }) });
        }
        case SET_SNACKBAR: {
            const { snackbarOpen, snackbarType, snackbarMsg } = action.payload;
            return Object.assign(Object.assign({}, state), { snackbar: {
                    snackbarOpen,
                    snackbarType,
                    snackbarMsg,
                } });
        }
        case GET_ALL_EXAM_SET_DETAILS: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { examSetDescriptionLoader: true }) });
        }
        case SAVE_EXAM_SET_DETAILS: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { screenLoader: true }) });
        }
        case SAVE_EXAM_SET_DETAILS_SUCCESS: {
            const { examID, res } = action.payload;
            const newExamSet = {};
            newExamSet[examID] = res;
            return Object.assign(Object.assign({}, state), { examSet: Object.assign(Object.assign({}, state.examSet), newExamSet), loader: Object.assign(Object.assign({}, state.loader), { examSetDescriptionLoader: false, screenLoader: false }) });
        }
        case GET_QUESTIONS_BY_EXAMSET: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { examQuestionLoader: true }) });
        }
        case GET_QUESTIONS_BY_EXAMSET_SUCCESS: {
            const { examSetID, res } = action.payload;
            return Object.assign(Object.assign({}, state), { questions: Object.assign(Object.assign({}, state.questions), { [examSetID]: (_b = (_a = res === null || res === void 0 ? void 0 : res[0]) === null || _a === void 0 ? void 0 : _a.questions) !== null && _b !== void 0 ? _b : [] }), loader: Object.assign(Object.assign({}, state.loader), { examQuestionLoader: false }) });
        }
        case SAVE_QUESTION_DETAILS: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state), { screenLoader: true }) });
        }
        case SAVE_QUESTION_DETAILS_SUCCESS: {
            const res = action.payload;
            let questions = (_c = state.questions[res.examSetID]) !== null && _c !== void 0 ? _c : [];
            questions = [...questions, res];
            return Object.assign(Object.assign({}, state), { questions: Object.assign(Object.assign({}, state.questions), { [res.examSetID]: questions }), loader: Object.assign(Object.assign({}, state), { screenLoader: false }) });
        }
        case GET_QUESTIONS_ON_PAGE_LOAD: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { examQuestionLoader: true }) });
        }
        case UPDATE_LOADER: {
            const { loader, value } = action.payload;
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { [loader]: value }) });
        }
        case POST_EXAM_PUBLISH_SUCCESS: {
            const examIndex = state.exams.findIndex((e) => e.examID === action.payload.examID);
            let exam = state.exams[examIndex];
            exam = Object.assign(Object.assign({}, exam), { publishStatus: true });
            const allExams = [...state.exams];
            allExams.splice(examIndex, 1, exam);
            return Object.assign(Object.assign({}, state), { exams: allExams });
        }
        case POST_UPDATE_EXAM_SUCCESS: {
            const examIndex = state.exams.findIndex((e) => e.examID === action.payload.examID);
            let exam = state.exams[examIndex];
            exam = Object.assign({}, action.payload);
            const allExams = [...state.exams];
            allExams.splice(examIndex, 1, exam);
            return Object.assign(Object.assign({}, state), { exams: allExams, loader: Object.assign(Object.assign({}, state.loader), { screenLoader: false }) });
        }
        case POST_UPDATE_EXAM: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { screenLoader: true }) });
        }
        case POST_SEARCH_EXAM: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { examListLoader: true }) });
        }
        case POST_SEARCH_EXAM_SUCCESS: {
            return Object.assign(Object.assign({}, state), { searchExams: [...action.payload], loader: Object.assign(Object.assign({}, state.loader), { examListLoader: false }) });
        }
        case POST_UPDATE_EXAMSET: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { screenLoader: true }) });
        }
        case POST_UPDATE_EXAMSET_SUCCESS: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { screenLoader: true }) });
        }
        case GET_EXAM_REPORT: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { examReport: true }) });
        }
        case GET_EXAM_REPORT_SUCCESS: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { examReport: false }), examReport: Object.assign({}, action.payload) });
        }
        case GET_DASHBOARD_REPORT: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { dashboardReport: true }) });
        }
        case GET_DASHBOARD_REPORT_SUCCESS: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { dashboardReport: false }), dashboardReport: Object.assign({}, action.payload) });
        }
        case GET_BILLING_REPORT: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { billingReport: true }) });
        }
        case GET_BILLING_REPORT_SUCCESS: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { billingReport: false }), billingReport: [...action.payload] });
        }
        case GET_STUDENTS_IN_EXAM: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { studentsInExams: true }) });
        }
        case GET_STUDENTS_IN_EXAM_SUCCESS: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { studentsInExams: false }), studentsInExam: { [action.payload.examID]: [...action.payload.data] } });
        }
        case GET_STUDENT_EXAM_RESULT_ANALYSIS: {
            return Object.assign(Object.assign({}, state), { studentExamResultAnalysisLoader: true });
        }
        case GET_STUDENT_EXAM_RESULT_ANALYSIS_SUCCESS: {
            return Object.assign(Object.assign({}, state), { studentExamResultAnalysisLoader: false, studentExamResultAnalysis: Object.assign(Object.assign({}, state.studentExamResultAnalysis), { [action.payload.examID]: Object.assign({}, action.payload.data) }) });
        }
        case POST_UPDATE_QUESTION: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state), { screenLoader: true }) });
        }
        case POST_UPDATE_QUESTION_SUCCESS: {
            const { examSetID, res, questionIndex } = action.payload;
            const questions = [...state.questions[examSetID]];
            questions[questionIndex] = res;
            return Object.assign(Object.assign({}, state), { questions: Object.assign(Object.assign({}, state.questions), { [examSetID]: questions }), loader: Object.assign(Object.assign({}, state), { screenLoader: false }) });
        }
        case POST_UPDATE_QUESTION_FAILURE: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state), { screenLoader: false }) });
        }
        case POST_TOP_LOADER_TRUE: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { screenLoader: true }) });
        }
        case POST_TOP_LOADER_FALSE: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { screenLoader: false }) });
        }
        case GET_STUDENT_TENANT_MAPPING: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { studentTenantMapping: true }) });
        }
        case GET_STUDENT_TENANT_MAPPING_SUCCESS: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { studentTenantMapping: false }), studentTenantMapping: [...action.payload] });
        }
        case GET_STUDENT_TENANT_MAPPING_FAILURE: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { studentTenantMapping: false }) });
        }
        default:
            return state;
    }
});
const reducer = combineReducers({
    exam: examReducer,
    auth: authReducer,
});
export default reducer;
