/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useStyle } from '../../styles/style';
import ExamSetList from './ExamSetList';
import Loader from '../../UIElements/Icons/Loader';
const ExamSet = () => {
    const classes = useStyle();
    const { examSetDescriptionLoader, examQuestionLoader } = useSelector((state) => state.exam.loader);
    return examSetDescriptionLoader || examQuestionLoader ? (React.createElement(Loader, { loaderClass: classes.loader, loaderDivClass: classes.setLoaderDiv })) : (React.createElement("div", { className: classes.examView },
        React.createElement("div", { className: classes.examTable, id: "examSetList" },
            React.createElement(ExamSetList, null)),
        React.createElement(Outlet, null)));
};
export default ExamSet;
