import { zodResolver } from '@hookform/resolvers/zod';
import { Button, TextField, Typography } from '@mui/material';
import { clsx } from 'clsx';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { postUpdateExamset, saveExamSetDetails } from '../../store/actions';
import { useStyle } from '../../styles/style';
const getSetDiv = (fields, classes, register, errors, access) => fields.map((field, index) => {
    var _a, _b, _c, _d, _e;
    return (React.createElement("div", { className: classes.setDescriptionDiv, key: field.id },
        React.createElement(Typography, { variant: "body2" }, `SET ${index + 1}`),
        React.createElement(TextField, Object.assign({ variant: "outlined", label: "Set Description", fullWidth: true, size: "small" }, register(`sets.${index}.examSetName`), { key: field.id, error: !!((_b = (_a = errors.sets) === null || _a === void 0 ? void 0 : _a[index]) === null || _b === void 0 ? void 0 : _b.examSetName), helperText: (_e = (_d = (_c = errors.sets) === null || _c === void 0 ? void 0 : _c[index]) === null || _d === void 0 ? void 0 : _d.examSetName) === null || _e === void 0 ? void 0 : _e.message, disabled: !access }))));
});
const ExamSetDescriptionForm = ({ examID, defaultValues, access, modify }) => {
    const classes = useStyle();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const examSetValidation = z.object({
        sets: z
            .object({
            examSetName: z
                .string()
                .nonempty('Set Description is required.')
                .max(500, `Description with can't be more than 500 characters`),
            examSetID: z.string().optional(),
        })
            .array(),
    });
    console.log(defaultValues);
    const examSetForm = useForm({
        defaultValues,
        resolver: zodResolver(examSetValidation),
        mode: 'onChange',
        values: defaultValues,
    });
    const { handleSubmit, control, register, formState: { errors }, } = examSetForm;
    const { fields } = useFieldArray({ name: 'sets', control });
    const setDiv = getSetDiv(fields, classes, register, errors, access);
    const onSubmit = (data, type) => {
        if (modify)
            dispatch(postUpdateExamset({ data, examID, navigate, type }));
        else
            dispatch(saveExamSetDetails({ data, examID, navigate, type }));
    };
    const handleNavigateButton = () => {
        navigate(`/${examID}/sets/1`);
    };
    return (React.createElement("div", { className: clsx(classes.examSetDescriptionForm, classes.scrollDefault) },
        React.createElement("form", null,
            setDiv,
            React.createElement("div", { className: classes.examFormButton }, access ? (React.createElement(React.Fragment, null,
                React.createElement(Button, { variant: "contained", disableElevation: true, color: "primary", type: "submit", onClick: handleSubmit((data) => onSubmit(data, 'submit')) }, modify ? 'Update' : 'Submit'),
                React.createElement(Button, { variant: "contained", disableElevation: true, color: "primary", type: "submit", onClick: handleSubmit((data) => onSubmit(data, 'next')) },
                    modify ? 'Update' : 'Submit',
                    " & Proceed to Question Creation"))) : (React.createElement(Button, { variant: "contained", disableElevation: true, color: "primary", onClick: handleNavigateButton }, "Proceed to Questions"))))));
};
export default ExamSetDescriptionForm;
