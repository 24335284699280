import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useStyle } from '../../styles/style';
import QuestionExcelModal from './QuestionExcelModal';
const QuestionExcelButton = ({ exam, examSet }) => {
    const classes = useStyle();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleButtonClick = () => {
        setIsModalOpen(true);
    };
    const handleClose = () => {
        setIsModalOpen(false);
    };
    return (React.createElement("div", { className: classes.questionExcelDiv },
        React.createElement(Button, { variant: "contained", size: "small", onClick: handleButtonClick }, "IMPORT USING EXCEL"),
        isModalOpen && (React.createElement(QuestionExcelModal, { open: isModalOpen, handleClose: handleClose, exam: exam, examSet: examSet }))));
};
export default QuestionExcelButton;
