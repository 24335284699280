import React from 'react';
import { useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { useParams, useNavigate } from 'react-router-dom';
const StudentReport = () => {
    var _a;
    const params = useParams();
    const navigate = useNavigate();
    const loader = useSelector((state) => state.exam.loader);
    const studentsInExam = useSelector((state) => state.exam.studentsInExam)[params.examID];
    const columns = [
        { field: 'id' },
        { field: 'studentName', headerName: 'Student Name', width: 150 },
        { field: 'emailID', headerName: 'Student Email ID', width: 240 },
        { field: 'marks', headerName: ' Marks', width: 240 },
    ];
    const rows = (_a = studentsInExam === null || studentsInExam === void 0 ? void 0 : studentsInExam.map((student) => {
        const e = {
            id: student.studentID,
            studentName: student.name,
            emailID: student.emailId,
            marks: student.marks_sum,
        };
        return e;
    })) !== null && _a !== void 0 ? _a : {};
    return (React.createElement("div", { style: { height: 600, width: '100%' } },
        React.createElement(DataGrid, { rows: rows, columns: columns, loading: loader.studentsInReport, columnVisibilityModel: {
                id: false,
            }, disableColumnMenu: true, onRowClick: (p) => navigate(`/${params.examID}/${p.id}/result-analysis`) })));
};
export default StudentReport;
