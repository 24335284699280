import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useStyle } from '../styles/style';
import SidePanel from './SidePanel';
const Body = () => {
    const classes = useStyle();
    const user = useSelector((state) => state.auth.user);
    return (React.createElement("div", { className: classes.body },
        user.tenantID ? React.createElement(SidePanel, null) : React.createElement(React.Fragment, null),
        React.createElement(Outlet, null)));
};
export default Body;
