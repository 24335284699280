import { Typography } from '@mui/material';
import React from 'react';
import { useStyle } from '../../styles/style';
const RefundPolicy = () => {
    const classes = useStyle();
    return (React.createElement("div", { id: "et-main-area", className: classes.privacyPolicy },
        React.createElement("div", { id: "main-content" },
            React.createElement("div", { className: classes.privacyPolicyTitle },
                React.createElement(Typography, { variant: "h1" }, "Refund Policy")),
            React.createElement("div", { className: "et_pb_text et_pb_module et_pb_bg_layout_light et_pb_text_align_left " },
                React.createElement("ol", null,
                    React.createElement("li", null,
                        React.createElement(Typography, null, "Submission of the examination set renders you ineligible for any refund.")),
                    React.createElement("li", null,
                        React.createElement(Typography, null, "If you have attempted more than 50% of the questions, a full refund will not be applicable. Refund eligibility will be determined based on a thorough analysis of the cause.")),
                    React.createElement("li", null,
                        React.createElement(Typography, null, "Refund claims based on the perceived quality of the questions will not be entertained.")),
                    React.createElement("li", null,
                        React.createElement(Typography, null, "Refund requests are only valid if the questions do not align with the relevant examination topics and are made following discussions with the teacher and subject matter experts.")),
                    React.createElement("li", null,
                        React.createElement(Typography, null, "Once your refund claim is approved, you can expect the refund within 30 days for payments made within India and within 3 months for international students.")),
                    React.createElement("li", null,
                        React.createElement(Typography, null, "If you do not receive the refund within the specified policy timeframe, please contact us at support@ZONATECHDIGITAL.com.")),
                    React.createElement("li", null,
                        React.createElement(Typography, null, "We retain the right to modify or replace this policy at our discretion, without prior notice to you."))),
                React.createElement(Typography, null, "For refund requests please email to info@startmyexam.com with Subject Line User ID and Examination name.")))));
};
export default RefundPolicy;
