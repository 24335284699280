import { Close } from '@mui/icons-material';
import { Box, IconButton, Modal } from '@mui/material';
import React from 'react';
import HTMLString from 'react-html-string';
import { useStyle } from '../../styles/style';
const QuestionPreviewModal = ({ open, handleClose, questionDescription, choices, }) => {
    const classes = useStyle();
    return (open && (React.createElement(Modal, { open: open, onClose: handleClose, disableScrollLock: true },
        React.createElement(Box, null,
            React.createElement("div", { className: classes.questionPreview },
                React.createElement("div", { className: classes.questionPreviewMain },
                    React.createElement("div", { className: classes.closeIcon },
                        React.createElement(IconButton, { onClick: handleClose },
                            React.createElement(Close, null))),
                    React.createElement("div", { className: classes.questionPreviewDescription },
                        React.createElement("div", null,
                            React.createElement(HTMLString, { html: questionDescription })),
                        React.createElement("div", { className: classes.questionPreviewMain }, choices === null || choices === void 0 ? void 0 : choices.map((choice, index) => (React.createElement("div", { key: index, className: classes.questionPreviewChoice },
                            React.createElement(HTMLString, { html: choice.choice }))))))))))));
};
export default QuestionPreviewModal;
