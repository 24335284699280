export const GET_ALL_EXAMS = 'GET_ALL_EXAMS';
export const GET_ALL_EXAMS_SUCCESS = 'GET_ALL_EXAMS_SUCCESS';
export const SAVE_EXAM_DETAILS = 'SAVE_EXAM_DETAILS';
export const SAVE_EXAM_DETAILS_SUCCESS = 'SAVE_EXAM_DETAILS_SUCCESS';
export const SAVE_EXAM_DETAILS_FAILURE = 'SAVE_EXAM_DETAILS_FAILURE';
export const GET_EXAM_BY_ID = 'GET_EXAM_BY_ID';
export const SET_SNACKBAR = 'SET_SNACKBAR';
export const SAVE_EXAM_SET_DETAILS = 'SAVE_EXAM_SET_DETAILS';
export const SAVE_EXAM_SET_DETAILS_SUCCESS = 'SAVE_EXAM_SET_DETAILS_SUCCESS';
export const GET_ALL_EXAM_SET_DETAILS = 'GET_ALL_EXAM_SET_DETAILS';
export const SAVE_QUESTION_DETAILS = 'SAVE_QUESTION_DETAILS';
export const SAVE_QUESTION_DETAILS_SUCCESS = 'SAVE_QUESTION_DETAILS_SUCCESS';
export const GET_QUESTIONS_BY_EXAMSET = 'GET_QUESTIONS_BY_EXAMSET';
export const GET_QUESTIONS_BY_EXAMSET_SUCCESS = 'GET_QUESTIONS_BY_EXAMSET_SUCCESS';
export const GET_QUESTIONS_ON_PAGE_LOAD = 'GET_QUESTIONS_ON_PAGE_LOAD';
export const UPDATE_LOADER = 'UPDATE_LOADER';
export const POST_EXAM_PUBLISH = 'POST_EXAM_PUBLISH';
export const POST_EXAM_PUBLISH_SUCCESS = 'POST_EXAM_PUBLISH_SUCCESS';
export const POST_UPDATE_EXAM = 'POST_UPDATE_EXAM';
export const POST_UPDATE_EXAM_SUCCESS = 'POST_UPDATE_EXAM_SUCCESS';
export const POST_SEARCH_EXAM = 'POST_SEARCH_EXAM';
export const POST_SEARCH_EXAM_SUCCESS = 'POST_SEARCH_EXAM_SUCCESS';
export const POST_UPDATE_EXAMSET = 'POST_UPDATE_EXAMSET';
export const POST_UPDATE_EXAMSET_SUCCESS = 'POST_UPDATE_EXAMSET_SUCCESS';
export const GET_EXAM_REPORT = 'GET_EXAM_REPORT';
export const GET_EXAM_REPORT_SUCCESS = 'GET_EXAM_REPORT_SUCCESS';
export const GET_EXAM_REPORT_FAILURE = 'GET_EXAM_REPORT_FAILURE';
export const GET_DASHBOARD_REPORT = 'GET_DASHBOARD_REPORT';
export const GET_DASHBOARD_REPORT_SUCCESS = 'GET_DASHBOARD_REPORT_SUCCESS';
export const GET_DASHBOARD_REPORT_FAILURE = 'GET_DASHBOARD_REPORT_FAILURE';
export const GET_BILLING_REPORT = 'GET_BILLING_REPORT';
export const GET_BILLING_REPORT_SUCCESS = 'GET_BILLING_REPORT_SUCCESS';
export const GET_BILLING_REPORT_FAILURE = 'GET_BILLING_REPORT_FAILURE';
export const GET_STUDENTS_IN_EXAM = 'GET_STUDENTS_IN_EXAM';
export const GET_STUDENTS_IN_EXAM_SUCCESS = 'GET_STUDENTS_IN_EXAM_SUCCESS';
export const GET_STUDENTS_IN_EXAM_FAILURE = 'GET_STUDENTS_IN_EXAM_FAILURE';
export const GET_STUDENT_EXAM_RESULT_ANALYSIS = 'GET_STUDENT_EXAM_RESULT_ANALYSIS';
export const GET_STUDENT_EXAM_RESULT_ANALYSIS_SUCCESS = 'GET_STUDENT_EXAM_RESULT_ANALYSIS_SUCCESS';
export const GET_STUDENT_EXAM_RESULT_ANALYSIS_FAILURE = 'GET_STUDENT_EXAM_RESULT_ANALYSIS_FAILURE';
export const POST_UPDATE_QUESTION = 'POST_UPDATE_QUESTION';
export const POST_UPDATE_QUESTION_SUCCESS = 'POST_UPDATE_QUESTION_SUCCESS';
export const POST_UPDATE_QUESTION_FAILURE = 'POST_UPDATE_QUESTION_FAILURE';
export const POST_CONTACT_US_DETAIL = 'POST_CONTACT_US_DETAIL';
export const POST_PAGE_AUDIT = 'POST_PAGE_AUDIT';
export const POST_TOP_LOADER_TRUE = 'POST_TOP_LOADER_TRUE';
export const POST_TOP_LOADER_FALSE = 'POST_TOP_LOADER_FALSE';
export const POST_DOWNLOAD_QUESTION_TEMPLATE = 'POST_DOWNLOAD_QUESTION_TEMPLATE';
export const GET_STUDENT_TENANT_MAPPING = 'GET_STUDENT_TENANT_MAPPING';
export const GET_STUDENT_TENANT_MAPPING_SUCCESS = 'GET_STUDENT_TENANT_MAPPING_SUCCESS';
export const GET_STUDENT_TENANT_MAPPING_FAILURE = 'GET_STUDENT_TENANT_MAPPING_FAILURE';
export const POST_STUDENT_STATUS = 'POST_STUDENT_STATUS';
