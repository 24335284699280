/* eslint-disable no-template-curly-in-string */
/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { useStyle } from '../../styles/style';
import Chips from '../BaseComponents/Chips';
const kababOptions = {
    'View Exam': '/exams/${props.examID}/view',
    'Modify Exam': '/exams/${props.examID}/edit',
    Sets: '/exams/${props.examID}/sets',
    Questions: '/${props.examID}/sets/1',
    Publish: '',
};
const ExamList = ({ search }) => {
    const { exams, searchExams } = useSelector((state) => state.exam);
    const prevExam = search && Object.keys(searchExams).length ? searchExams : exams;
    const classes = useStyle();
    return (React.createElement("div", { className: clsx(classes.examList, classes.scrollHidden) }, prevExam === null || prevExam === void 0 ? void 0 : prevExam.map((exam) => (React.createElement(Chips, { label: exam.examName, key: exam.examID, examID: exam.examID, sets: exam.noOfSets, questionsPerSet: exam.noOfQuestionsPerSet, examPrice: exam.totalExamPrice, publishStatus: exam.publishStatus, kababOptions: kababOptions, to: "/exams/${props.examID}/view", classes: {
            chip: classes.chip,
            chipBottom: classes.chipBottom,
            chipRightBottom: classes.chipRightBottom,
            chipTitle: classes.chipTitle,
            chipTopRight: classes.chipTopRight,
            moreVertIcon: classes.moreVertIcon,
            publish: classes.publish,
            chipMain: classes.chipMain,
            navLink: classes.navLink,
        } })))));
};
export default ExamList;
