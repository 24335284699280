export const PRIMARY_COLOR = {
    GREY0: '#F0F0F0',
    GREY10: '#E0E0E0',
    GREY20: '#D8D8D8',
    GREY40: '#C8C8C8',
    GREY50: '#BEBEBE',
    GREY60: '#A9A9A9',
    GREY80: '#989898',
    GREY100: '#808080',
    GREY125: '#696969',
    GREY150: '#383838',
    PRIMARY: '#00a2ed',
    BACKGROUND: '#101e2a',
};
