/* eslint-disable react/jsx-no-useless-fragment */
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, TextField, Typography } from '@mui/material';
import { useTour } from '@reactour/tour';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import { postUpdateExam, saveExamDetails } from '../../store/actions';
import { useStyle } from '../../styles/style';
import { stringToBoolean } from '../../utils/commonUtils';
import EditorRTE from '../BaseComponents/EditorRTE';
import RadioGroup from '../BaseComponents/RadioGroup';
const defaultValues = {
    examName: '',
    examDescription: '',
    disclosure: '',
    totalExamPrice: null,
    noOfQuestionsPerSet: null,
    correctAnswerMark: null,
    negativeAnswerMark: null,
    durationInMinute: null,
    noOfSets: null,
    negativeMarkingAllowed: 'NO',
};
const numberMessage = {
    required_error: 'Value is expected',
    invalid_type_error: 'Number is expected',
};
const ExamForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { values, view, update } = props;
    const { setIsOpen } = useTour();
    const dispatch = useDispatch();
    const classes = useStyle();
    const navigate = useNavigate();
    const { examID } = useParams();
    const formValidation = z
        .object({
        examName: z.string().nonempty('Exam Name is Required').max(500),
        examDescription: z
            .string()
            .nonempty('Exam Description is Required')
            .min(100)
            .max(5000),
        disclosure: z.string(),
        totalExamPrice: z.number(numberMessage).min(10).max(5000),
        noOfQuestionsPerSet: z.number(numberMessage).min(10).max(500),
        correctAnswerMark: z.number(numberMessage).min(1).max(1000),
        negativeAnswerMark: z.number(numberMessage).max(1000),
        durationInMinute: z.number(numberMessage).min(5).max(240),
        noOfSets: z.number(numberMessage).min(1).max(25),
        negativeMarkingAllowed: z.enum(['YES', 'NO']),
    })
        .superRefine((formValues, ctx) => {
        if (stringToBoolean(formValues.negativeMarkingAllowed) &&
            !formValues.negativeAnswerMark) {
            ctx.addIssue({
                message: 'Value is required',
                code: z.ZodIssueCode.custom,
                path: ['negativeAnswerMark'],
            });
        }
    });
    const examForm = useForm({
        defaultValues,
        resolver: zodResolver(formValidation),
        mode: 'onChange',
        values,
    });
    const { register, handleSubmit, formState: { errors }, watch, setValue, control, } = examForm;
    const isNegMarkingEnabled = stringToBoolean(watch('negativeMarkingAllowed', values.negativeMarkingAllowed));
    useEffect(() => {
        if (!isNegMarkingEnabled)
            setValue('negativeAnswerMark', 0);
    }, [isNegMarkingEnabled]);
    const onSubmit = (data, type) => {
        const tempData = Object.assign(Object.assign({}, data), { multipleChoice: false, negativeMarkingAllowed: stringToBoolean(data.negativeMarkingAllowed), teacherName: '' });
        const payload = {
            exam: update ? Object.assign(Object.assign({}, tempData), { examID }) : tempData,
            navigate,
            type,
        };
        if (update)
            dispatch(postUpdateExam(payload));
        else
            dispatch(saveExamDetails(payload));
    };
    return (React.createElement("div", { className: classes.examFormBox, id: "examForm" },
        React.createElement(Typography, { className: classes.examFormTitle }, "Create / Modify Exam"),
        React.createElement("form", { className: clsx(classes.examFormMain, classes.scrollHidden) },
            React.createElement("div", { className: classes.examFormBody },
                React.createElement(TextField, Object.assign({ label: "Exam Name", variant: "outlined", fullWidth: true, size: "small", className: classes.formInput }, register('examName'), { error: !!errors.examName, helperText: (_a = errors.examName) === null || _a === void 0 ? void 0 : _a.message, disabled: view, id: "examName" })),
                React.createElement("div", { className: classes.examFormMultiline, id: "examDescription" },
                    React.createElement(Controller, { control: control, name: "examDescription", render: ({ field: { onChange, value } }) => (React.createElement(EditorRTE, { onChange: onChange, value: value, errors: errors.examDescription, disabled: view, placeholder: "Exam Description" })) })),
                React.createElement("div", { className: classes.examFormDiv },
                    React.createElement(TextField, Object.assign({ label: "Total Exam Price (For all set In INR)", variant: "outlined", size: "small", type: "tel", className: clsx(classes.formInput, classes.formInputNumber) }, register('totalExamPrice', { valueAsNumber: true }), { error: !!errors.totalExamPrice, helperText: (_b = errors.totalExamPrice) === null || _b === void 0 ? void 0 : _b.message, disabled: view, id: "totalExamPrice" })),
                    React.createElement(TextField, Object.assign({ label: "No Of Questions per set", variant: "outlined", size: "small", type: "tel", className: clsx(classes.formInput, classes.formInputNumber) }, register('noOfQuestionsPerSet', { valueAsNumber: true }), { error: !!errors.noOfQuestionsPerSet, helperText: (_c = errors.noOfQuestionsPerSet) === null || _c === void 0 ? void 0 : _c.message, disabled: view, id: "noOfQuestionsPerSet" })),
                    React.createElement(TextField, Object.assign({ label: "Duration (in Minutes)", variant: "outlined", size: "small", type: "tel", className: clsx(classes.formInput, classes.formInputNumber) }, register('durationInMinute', { valueAsNumber: true }), { error: !!errors.durationInMinute, helperText: (_d = errors.durationInMinute) === null || _d === void 0 ? void 0 : _d.message, disabled: view, id: "duration" }))),
                React.createElement("div", { className: classes.examFormDiv },
                    React.createElement(TextField, Object.assign({ label: "Marks for each correct answer", variant: "outlined", size: "small", type: "tel", className: clsx(classes.formInput, classes.formInputNumber) }, register('correctAnswerMark', { valueAsNumber: true }), { error: !!errors.correctAnswerMark, helperText: (_e = errors.correctAnswerMark) === null || _e === void 0 ? void 0 : _e.message, disabled: view, id: "marksForEachCorrectAnswer" })),
                    React.createElement(TextField, Object.assign({ label: "No of Sets", variant: "outlined", size: "small", type: "tel", className: clsx(classes.formInput, classes.formInputNumber) }, register('noOfSets', { valueAsNumber: true }), { error: !!errors.noOfSets, helperText: (_f = errors.noOfSets) === null || _f === void 0 ? void 0 : _f.message, disabled: view, id: "noOfSets" }))),
                React.createElement("div", { className: classes.examFormDiv },
                    React.createElement("div", { id: "isNegativeMarkingEnabled" },
                        React.createElement(Controller, { control: control, name: "negativeMarkingAllowed", render: ({ field: { onChange } }) => (React.createElement(RadioGroup, { labels: ['YES', 'NO'], onChange: onChange, defaultValue: values.negativeMarkingAllowed, parentLabel: "Negative Marking", row: true, disabled: view })) })),
                    isNegMarkingEnabled ? (React.createElement(TextField, Object.assign({ label: "Marks for each wrong answer", variant: "outlined", size: "small", type: "tel", className: clsx(classes.formInput, classes.formInputNumber) }, register('negativeAnswerMark', { valueAsNumber: true }), { error: !!errors.negativeAnswerMark, helperText: (_g = errors.negativeAnswerMark) === null || _g === void 0 ? void 0 : _g.message, disabled: view }))) : (React.createElement("div", { className: classes.hiddenDiv }))),
                !view ? (React.createElement("div", { className: classes.examFormButton },
                    React.createElement(Button, { variant: "contained", disableElevation: true, color: "primary", type: "submit", onClick: handleSubmit((data) => {
                            setIsOpen(false);
                            onSubmit(data, 'submit');
                        }), id: "submitExam" }, update ? 'Update' : 'Submit'),
                    update ? (React.createElement(React.Fragment, null)) : (React.createElement(Button, { variant: "contained", disableElevation: true, color: "primary", type: "submit", id: "submitProceedExam", onClick: handleSubmit((data) => {
                            setIsOpen(false);
                            onSubmit(data, 'next');
                        }) }, "Proceed to Exam Set Creation")))) : (React.createElement(React.Fragment, null))))));
};
export default ExamForm;
