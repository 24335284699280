import React from 'react';
import { useStyle } from '../styles/style';
import Body from './Body';
import Header from './Header';
import NewFooter from './NewFooter';
import Modals from './Modals';
const MainComponent = () => {
    const classes = useStyle();
    return (React.createElement("div", { className: classes.main },
        React.createElement(Header, null),
        React.createElement(Body, null),
        React.createElement(NewFooter, null),
        React.createElement(Modals, null)));
};
export default MainComponent;
