import { Close } from '@mui/icons-material';
import { Box, Button, IconButton, Modal, TextField, Typography, } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPasswordModalClose, loginModalOpen, postPageAudit, postTenantForgotPassword, } from '../../store/actions';
import { useStyle } from '../../styles/style';
const ForgotPassword = () => {
    const dispatch = useDispatch();
    const classes = useStyle();
    const [emailID, setEmailID] = useState('');
    const { forgotPasswordModal } = useSelector((state) => state.auth.modals);
    const handleClose = () => {
        dispatch(postPageAudit({
            page: 'TEACHER PORTAL:FORGOT PASSWORD MODAL CLOSE',
        }));
        dispatch(forgotPasswordModalClose());
    };
    const handleLoginClick = () => {
        dispatch(postPageAudit({
            page: 'TEACHER PORTAL:LOGIN MODAL OPEN',
        }));
        dispatch(loginModalOpen());
    };
    const handleSubmit = () => {
        dispatch(postPageAudit({
            page: 'TEACHER PORTAL:FORGOT PASSWORD MODAL BUTTON CLICK',
        }));
        dispatch(postTenantForgotPassword({ emailID }));
    };
    return (forgotPasswordModal && (React.createElement(Modal, { open: forgotPasswordModal, onClose: handleClose, disableScrollLock: true },
        React.createElement(Box, { className: classes.loginModal },
            React.createElement("div", { className: classes.closeIconBody },
                React.createElement("div", { className: classes.loginHeader }, "Forgot Password"),
                React.createElement(IconButton, { onClick: handleClose, size: "small" },
                    React.createElement(Close, null))),
            React.createElement("div", { className: classes.loginBody },
                React.createElement(Typography, null, "We'll email you a link to reset your password"),
                React.createElement(TextField, { variant: "outlined", type: "email", size: "small", fullWidth: true, label: "Email", onChange: (e) => setEmailID(e.target.value) }),
                React.createElement(Button, { variant: "contained", fullWidth: true, onClick: handleSubmit }, "Send me a password reset link")),
            React.createElement("div", { className: classes.rowAlign },
                React.createElement(Typography, null, "Remember you password?"),
                React.createElement(Button, { onClick: handleLoginClick }, "Login"))))));
};
export default ForgotPassword;
