import { makeStyles } from '@material-ui/core';
import { PRIMARY_COLOR } from './colors';
export const useStyle = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        background: 'white',
        height: theme.typography.pxToRem(48),
        alignItems: 'center',
        padding: `0 ${theme.typography.pxToRem(16)}`,
        boxShadow: '0 4px 13px -3px rgba(0, 0, 0, 0.10196)',
    },
    footer: {
        background: PRIMARY_COLOR.BACKGROUND,
        padding: `${theme.typography.pxToRem(32)} ${theme.typography.pxToRem(48)} ${theme.typography.pxToRem(24)}`,
    },
    sidePanelBody: {
        minWidth: theme.typography.pxToRem(208),
        background: PRIMARY_COLOR.GREY0,
        minHeight: '89vh',
        transition: 'min-width 0.3s',
    },
    sidePanelList: {
        listStyle: 'none',
        marginTop: theme.typography.pxToRem(30),
        padding: `0 ${theme.typography.pxToRem(12)}`,
        '& li': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
            margin: `${theme.typography.pxToRem(12)} 0`,
            '&:hover': {
                background: PRIMARY_COLOR.GREY50,
                color: PRIMARY_COLOR.GREY150,
            },
        },
    },
    backButton: {
        float: 'right',
        marginRight: theme.typography.pxToRem(12),
        color: PRIMARY_COLOR.GREY150,
        '&:hover': {
            background: PRIMARY_COLOR.GREY50,
            borderRadius: '50%',
        },
    },
    rotate: {
        transform: 'rotate(180deg)',
    },
    icon: {
        height: `${theme.typography.pxToRem(48)} !important`,
        padding: `0 ${theme.typography.pxToRem(16)}`,
    },
    navLink: {
        textDecoration: 'none',
        color: PRIMARY_COLOR.GREY150,
    },
    title: {
        paddingLeft: theme.typography.pxToRem(16),
    },
    body: {
        display: 'flex',
        minHeight: 'calc(100vh - 109px)',
    },
    typo: {
        color: 'white',
        fontSize: theme.typography.pxToRem(12),
        fontFamily: 'cursive',
    },
    examList: {
        overflowY: 'scroll',
        height: 'calc(100vh - 148px)',
        borderTop: `1px solid ${PRIMARY_COLOR.GREY10}`,
    },
    scrollHidden: {
        '&::-webkit-scrollbar': {
            width: '0',
        },
        // '&::-webkit-scrollbar-track': {
        //   background: 'white' /* color of the tracking area */,
        // },
        // '&::-webkit-scrollbar-thumb': {
        //   backgroundColor: '#585858' /* color of the scroll thumb */,
        //   borderRadius: '20px' /* roundness of the scroll thumb */,
        // },
    },
    chipMain: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: `1px solid ${PRIMARY_COLOR.GREY10}`,
        padding: theme.typography.pxToRem(6),
    },
    chip: {
        height: theme.typography.pxToRem(51),
        width: theme.typography.pxToRem(336),
        display: 'flex',
        flexWrap: 'wrap',
    },
    chipHighlight: {
        background: PRIMARY_COLOR.GREY10,
    },
    chipTitle: {
        width: theme.typography.pxToRem(144),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: theme.typography.pxToRem(20),
        whiteSpace: 'nowrap',
        color: '#181818',
        fontSize: '13px !important',
        flex: '70%',
        '&:hover': {
            overflow: 'visible',
            textOverflow: 'clip',
            whiteSpace: 'noWrap',
            wordBreak: 'break-all',
        },
    },
    chipBottom: {
        color: PRIMARY_COLOR.GREY100,
        flex: '70%',
    },
    chipRightBottom: {
        flex: '30%',
        textAlign: 'right',
    },
    chipTopRight: {
        flex: '30%',
        textAlign: 'right',
        color: '#181818',
    },
    examHeader: {
        margin: theme.typography.pxToRem(11),
        display: 'flex',
        justifyContent: 'space-between',
    },
    examView: {
        display: 'flex',
        flexDirection: 'row',
    },
    examFormBox: {
        minWidth: theme.typography.pxToRem(720),
        maxWidth: theme.typography.pxToRem(720),
        boxShadow: '0 16px 10px 10px rgba(0, 0, 0, 0.10196)',
        maxHeight: '80vh',
        margin: `${theme.typography.pxToRem(16)} 0 0 ${theme.typography.pxToRem(16)}`,
        padding: `${theme.typography.pxToRem(16)} `,
        transition: 'min-width 0.3s',
    },
    formInput: {
        margin: ` 0 ${theme.typography.pxToRem(8)} !important`,
    },
    hover: {
        '& .Mui-error': {
            margin: 0,
            '&:hover fieldset': {
                border: `2px solid ${theme.palette.error.main} !important`,
            },
        },
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                border: `2px solid ${theme.palette.primary.main} !important`,
            },
        },
    },
    loader: {
        height: theme.typography.pxToRem(16),
        mixBlendMode: 'multiply',
    },
    loaderDiv: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        width: theme.typography.pxToRem(342),
    },
    examTable: {
        minWidth: theme.typography.pxToRem(352),
        display: 'flex',
        flexDirection: 'column',
        borderRight: `1px solid ${PRIMARY_COLOR.GREY10}`,
        maxHeight: '79vh',
        transition: 'min-width 0.3s',
    },
    moreVertIcon: {
        right: theme.typography.pxToRem(1),
        position: 'absolute',
    },
    examFormButton: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: `${theme.typography.pxToRem(16)} 0`,
    },
    examFormDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: `${theme.typography.pxToRem(11)} !important`,
    },
    examFormMain: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'scroll',
        padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(16)} 0 0`,
        height: '78vh',
    },
    formLabel: {
        '&.Mui-focused': {
            color: 'rgba(0, 0, 0, 0.6) !important',
        },
    },
    examFormBody: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '& > *': {
            margin: `${theme.typography.pxToRem(11)} 0`,
        },
    },
    examFormMultiline: {
        marginLeft: theme.typography.pxToRem(11),
    },
    hiddenDiv: {
        width: theme.typography.pxToRem(225),
    },
    publish: {
        color: '#4CBB17',
    },
    formInputNumber: {
        width: theme.typography.pxToRem(208),
    },
    examFormTitle: {
        color: PRIMARY_COLOR.GREY150,
    },
    setBox: {
        maxWidth: theme.typography.pxToRem(720),
        margin: theme.typography.pxToRem(16),
    },
    questionForm: {
        '& > *': {
            marginBottom: `${theme.typography.pxToRem(20)} !important`,
        },
    },
    questionFormDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    choiceDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    scrollDefault: {
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: '2px',
        },
        '&::-webkit-scrollbar-track': {
            background: 'white' /* color of the tracking area */,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#585858' /* color of the scroll thumb */,
            borderRadius: '20px' /* roundness of the scroll thumb */,
        },
    },
    tabContext: {
        height: theme.typography.pxToRem(400),
        padding: `${theme.typography.pxToRem(12)} !important`,
    },
    addButtonDiv: {
        background: `${PRIMARY_COLOR.GREY0} !important`,
        borderRadius: '0 !important',
        width: '89%',
        color: `${PRIMARY_COLOR.GREY80} !important`,
        margin: `0 ${theme.typography.pxToRem(32)}  0 ${theme.typography.pxToRem(48)}  !important`,
    },
    choiceInput: {
        marginRight: `${theme.typography.pxToRem(15)} !important`,
    },
    crossIcon: {
        fill: `${PRIMARY_COLOR.GREY60} !important`,
        width: `${theme.typography.pxToRem(20)} !important`,
    },
    blue: {
        color: '#00a2ed',
    },
    setDescriptionDiv: {
        border: `${theme.typography.pxToRem(1)} ${PRIMARY_COLOR.GREY80} solid`,
        borderRadius: theme.typography.pxToRem(5),
        padding: theme.typography.pxToRem(16),
        margin: `0 ${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(16)} 0`,
    },
    setLoaderDiv: {
        display: 'flex',
        width: theme.typography.pxToRem(720),
        alignItems: 'center',
        justifyContent: 'center',
    },
    examSetHeader: {
        borderBottom: `${theme.typography.pxToRem(2)} solid ${PRIMARY_COLOR.GREY10}`,
    },
    questionTab: {
        minHeight: `${theme.typography.pxToRem(32)} !important`,
    },
    examSetDescriptionForm: {
        maxHeight: '76vh',
        marginTop: theme.typography.pxToRem(12),
    },
    questionFormButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: theme.typography.pxToRem(12),
    },
    error: {
        color: theme.palette.error.main,
    },
    curtain: {
        background: PRIMARY_COLOR.GREY0,
        position: 'absolute',
        opacity: '50%',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
    },
    questionPreview: {
        width: '100%',
        height: '100%',
        zIndex: 999,
        position: 'absolute',
        background: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
    },
    questionPreviewDescription: {
        flex: '100%',
        padding: ` 0 ${theme.typography.pxToRem(16)}`,
    },
    questionPreviewMain: {
        background: PRIMARY_COLOR.GREY0,
        margin: `${theme.typography.pxToRem(16)}`,
        padding: `${theme.typography.pxToRem(12)} !important`,
    },
    closeIcon: {
        display: 'flex !important',
        justifyContent: 'flex-end !important',
    },
    searchExpand: {
        width: '198px',
        transition: 'width 0.3s',
        margin: '-2px 10px !important',
        '& .MuiOutlinedInput-root': {
            height: '32px',
        },
    },
    searchCollapse: {
        transition: 'width 0.3s',
        margin: '-2px 10px !important',
        width: '32px',
        '& .MuiOutlinedInput-root': {
            borderRadius: '50%',
            height: '32px',
        },
    },
    searchIcon: {
        marginLeft: '-17px !important',
    },
    questionPreviewChoice: {
        background: PRIMARY_COLOR.GREY50,
        padding: theme.typography.pxToRem(4),
        margin: `${theme.typography.pxToRem(16)} !important`,
    },
    footerLogo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        '@media (max-width: 480px)': { flexDirection: 'column' },
    },
    divider: {
        border: `1px ${PRIMARY_COLOR.GREY150} solid`,
        margin: `${theme.typography.pxToRem(16)} 0`,
    },
    footerContent: {
        '& > *': {
            margin: `${theme.typography.pxToRem(6)} !important`,
        },
    },
    dashboardReportSkeleton: {
        display: 'flex',
        padding: theme.typography.pxToRem(16),
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignContent: 'flex-start',
        '& > *': {
            marginRight: theme.typography.pxToRem(4),
        },
    },
    dashboardBox: {
        background: PRIMARY_COLOR.GREY0,
        minWidth: theme.typography.pxToRem(600),
        padding: theme.typography.pxToRem(16),
        borderRadius: theme.typography.pxToRem(4),
        marginBottom: theme.typography.pxToRem(8),
        marginRight: theme.typography.pxToRem(4),
    },
    alignText: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0.2rem',
    },
    contactUsBody: {
        border: `1px solid ${PRIMARY_COLOR.GREY80}`,
        margin: theme.typography.pxToRem(48),
        padding: theme.typography.pxToRem(48),
        display: 'flex',
    },
    contactUsLogo: {
        flex: '0 0 50%',
        padding: theme.typography.pxToRem(16),
        borderRight: `1px solid ${PRIMARY_COLOR.GREY80}`,
    },
    contactUsForm: {
        paddingLeft: theme.typography.pxToRem(32),
        flex: '50%',
    },
    resultAnalysis: {
        minHeight: '90vh',
        padding: theme.typography.pxToRem(32),
    },
    resultAnalysisTM: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.typography.pxToRem(32),
        borderBottom: '4px solid rgba(224, 224, 224, 1)',
    },
    resultAnalysisSA: {},
    resultAnalysisTableHeader: {
        '& .MuiTableCell-head': {
            color: PRIMARY_COLOR.GREY100,
        },
    },
    resultAnalysisTableRow: {
        '& .MuiTableCell-root': {
            fontSize: theme.typography.pxToRem(16),
        },
    },
    resultAnalysisChartMain: {
        display: 'flex',
        flexDirection: 'row',
        '& > *': {
            marginRight: theme.typography.pxToRem(8),
        },
    },
    resultPieChart: {
        padding: theme.typography.pxToRem(16),
        border: `1px solid ${PRIMARY_COLOR.GREY60}`,
    },
    resultPCFooter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    resultAnalysisHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.typography.pxToRem(12),
        padding: theme.typography.pxToRem(8),
        borderBottom: '4px solid rgba(224, 224, 224, 1)',
    },
    contactUsBanner: {
        backgroundColor: PRIMARY_COLOR.PRIMARY,
        textAlign: 'center',
    },
    contactUsHead: {
        padding: '4.7rem 0 5rem',
        maxWidth: theme.typography.pxToRem(1250),
        margin: '0 auto',
    },
    tourIcon: { position: 'absolute', right: '0', top: '50%' },
    privacyPolicy: {
        marginTop: theme.typography.pxToRem(48),
        padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(120)} ${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(120)}`,
    },
    privacyPolicyTitle: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '2rem',
        color: PRIMARY_COLOR.PRIMARY,
    },
    tncHeading: { padding: '1rem 1rem 1rem 0', color: PRIMARY_COLOR.PRIMARY },
    landingPageMain: {
        padding: theme.typography.pxToRem(16),
        width: '100vw',
        '@media (max-width: 480px)': { padding: theme.typography.pxToRem(0) },
    },
    imageSlider: {
        width: '100%',
        height: theme.typography.pxToRem(400),
        borderRadius: theme.typography.pxToRem(10),
        backgroundSize: 'cover',
        animation: `$fading 3000ms linear`,
        backgroundPosition: 'center',
        '@media (max-width: 480px)': {},
    },
    '@keyframes fading': {
        from: { opacity: '1.0' },
        to: { opacity: '0.2' },
    },
    sliderRightArrow: {
        position: 'absolute',
        top: '50%',
        transform: 'translate(0, -50%)',
        right: '32px',
        zIndex: 1,
        backgroundColor: PRIMARY_COLOR.GREY125,
        borderRadius: '50%',
    },
    sliderLeftArrow: {
        position: 'absolute',
        top: '50%',
        transform: 'translate(0, -50%)',
        left: '32px',
        zIndex: 1,
        backgroundColor: PRIMARY_COLOR.GREY125,
        borderRadius: '50%',
    },
    sliderStyles: {
        position: 'relative',
        height: '100%',
    },
    textSliderStyles: {
        position: 'relative',
        height: theme.typography.pxToRem(260),
        padding: '2rem',
    },
    textSliderText: {
        fontFamily: ` 'SF Pro Text',-apple-system,BlinkMacSystemFont,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol' `,
        position: 'relative',
        // animation: `$animatetop 5000ms ${theme.transitions.easing.easeInOut} infinite`,
    },
    '@keyframes animatetop': {
        '0%': { marginTop: '10em' },
        '4%': { marginTop: '0em' },
        '31%': { marginTop: '0em' },
        '35%': { marginTop: '-10em' },
        '64%': { marginTop: '-10em' },
        '68%': { marginTop: '-20em' },
        '96%': { marginTop: '-500em' },
        '100%': { marginTop: '-500em' },
    },
    slideOverLayText: {
        border: `2px solid rgba(240, 240, 240)`,
        borderRadius: '1rem',
        padding: theme.typography.pxToRem(12),
    },
    dotsContainerStyles: {
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        bottom: 0,
        left: '30%',
        transform: 'translate(0, -50%)',
        borderRadius: '6px',
    },
    dotStyle: { margin: '0 3px', height: '24px' },
    signUp: {
        padding: theme.typography.pxToRem(16),
        border: `${theme.typography.pxToRem(2)} solid ${PRIMARY_COLOR.PRIMARY} `,
        borderRadius: '4px',
        '& > *': {
            marginBottom: `${theme.typography.pxToRem(10)} !important`,
        },
    },
    passwordList: {
        fontSize: theme.typography.pxToRem(12),
        margin: 0,
    },
    pointer: {
        cursor: 'pointer',
    },
    rowAlign: {
        display: 'flex',
        alignItems: 'center',
    },
    landingPageTopFlex: {
        display: 'flex',
        marginBottom: '1rem',
        '@media (max-width: 480px)': { flexDirection: 'column' },
    },
    loginModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: theme.typography.pxToRem(400),
        background: 'white',
        padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(32)}`,
        '@media (max-width: 480px)': {
            width: theme.typography.pxToRem(300),
        },
    },
    closeIconBody: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    loginHeader: {
        fontSize: theme.typography.pxToRem(28),
        fontWeight: 600,
        marginBottom: theme.typography.pxToRem(16),
    },
    loginBody: {
        '& > *': {
            marginBottom: `${theme.typography.pxToRem(10)} !important`,
        },
    },
    forgotPassword: {
        cursor: 'pointer',
        color: PRIMARY_COLOR.PRIMARY,
        margin: theme.typography.pxToRem(4),
    },
    timeline: {
        transform: 'rotate(270deg)',
    },
    timelineContentContainer: {
        textAlign: 'left',
    },
    timelineContent: {
        display: 'inline-block',
        transform: 'rotate(90deg)',
        textAlign: 'center',
        minWidth: 50,
        padding: '1rem',
    },
    timelineIcon: {
        transform: 'rotate(90deg)',
    },
    landingPageTitle: {
        fontSize: '3.5rem',
        fontWeight: 600,
        '@media (max-width: 480px)': {
            fontSize: '3.2rem',
        },
    },
    landingPageData: {
        width: '45%',
        display: 'flex',
        padding: '5rem 5rem 0 5rem',
        flexDirection: 'column',
        '@media (max-width: 480px)': {
            width: 'auto',
            padding: '2rem 2rem 0 2rem',
            marginBottom: '2rem',
        },
    },
    loginButton: {
        width: '18rem',
        fontSize: '1.75rem !important',
        padding: '1rem',
        height: '4rem',
        '@media (max-width: 480px)': {
            fontSize: '1rem !important',
            padding: '2rem ',
            height: '3.2rem',
        },
    },
    landingPageVideoDiv: {
        display: 'flex',
        '@media (max-width: 480px)': {
            flexDirection: 'column',
        },
    },
    landingPageVideo: {
        width: '68vw',
        '@media (max-width: 480px)': {
            width: 'auto',
            margin: '0 1rem 2rem 1rem',
        },
    },
    landingPageTimelineImg: {
        width: '28vw',
        '@media (max-width: 480px)': {
            width: 'auto',
            margin: '0 1rem 2rem 1rem',
        },
    },
    forgotPasswordRequest: {
        marginTop: theme.typography.pxToRem(48),
    },
    forgotPasswordRequestBox: {
        margin: '8rem 30rem',
        boxShadow: `0 0 0.5rem ${PRIMARY_COLOR.GREY100}`,
        padding: '2rem',
        width: '30rem',
    },
    questionExcelDiv: {
        display: 'flex',
        flexDirection: 'row-reverse',
        margin: '4px',
    },
    questionExcel: {
        zIndex: 99,
        background: theme.palette.common.white,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    questionExcelHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    questionExcelBody: { padding: '2rem' },
}));
