/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-eval */
import { Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { postExamPublish } from '../../store/actions';
import { useStyle } from '../../styles/style';
const KababRender = (props) => {
    const classes = useStyle();
    const dispatch = useDispatch();
    const { open, anchorEl, handleClose, kababOptions, publishStatus } = props;
    const handlePublishClick = () => {
        dispatch(postExamPublish({ examID: props.examID }));
    };
    return (React.createElement(Menu, { id: "kabab-menu", open: open, keepMounted: true, anchorEl: anchorEl, onClick: handleClose, onClose: handleClose }, Object.keys(kababOptions).map((key) => {
        const modifyEnabled = key === 'Modify Exam' && publishStatus;
        if (key !== 'Publish' && key !== 'ShareOn')
            return (React.createElement(Link, { to: modifyEnabled ? '#' : eval(`\`${kababOptions[key]}\``), key: key, className: classes.navLink },
                React.createElement(MenuItem, { disabled: modifyEnabled }, key)));
        if (key === 'ShareOn') {
            return React.createElement(MenuItem, { key: key }, "Share On");
        }
        return (React.createElement(MenuItem, { onClick: handlePublishClick, key: key }, key));
    })));
};
export default KababRender;
