/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Loader from '../../UIElements/Icons/Loader';
import { useStyle } from '../../styles/style';
import ExamList from './ExamList';
import ExamsHeader from './ExamsHeader';
import { postSearchExam } from '../../store/actions';
const Exams = () => {
    const [search, setSearch] = useState('');
    const loader = useSelector((state) => state.exam.loader.examListLoader);
    const classes = useStyle();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!search)
            return;
        const timer = setTimeout(() => {
            dispatch(postSearchExam({ search }));
        }, 2000);
        return () => clearTimeout(timer);
    }, [search]);
    return (React.createElement("div", { className: classes.examView },
        React.createElement("div", { className: classes.examTable },
            React.createElement(ExamsHeader, { setSearch: setSearch }),
            loader ? (React.createElement(Loader, { loaderClass: classes.loader, loaderDivClass: classes.loaderDiv })) : (React.createElement(ExamList, { search: search }))),
        React.createElement(Outlet, null)));
};
export default Exams;
