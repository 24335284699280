export const steps = [
    {
        selector: '#exams',
        content: 'Click here to see all the exams created and create a new exam.',
    },
    {
        selector: '#createExam',
        content: 'Click here to create a new exam',
    },
];
export const createExamSteps = [
    {
        selector: '#examName',
        content: 'Enter exam name here.',
    },
    {
        selector: '#examDescription',
        content: 'Enter exam description here.',
    },
    {
        selector: '#totalExamPrice',
        content: 'Enter price for total exam.',
    },
    {
        selector: '#noOfQuestionsPerSet',
        content: 'Enter number of questions for each set.',
    },
    {
        selector: '#duration',
        content: 'Enter duration for each set.',
    },
    {
        selector: '#marksForEachCorrectAnswer',
        content: 'Enter marks for each correct answer.',
    },
    {
        selector: '#noOfSets',
        content: 'Enter number of sets.',
    },
    {
        selector: '#isNegativeMarkingEnabled',
        content: 'Select Yes if you want to enable negative marking.',
    },
    {
        selector: '#submitExam',
        content: 'Press button to save the exam.',
    },
    {
        selector: '#submitProceedExam',
        content: 'Press button to save the exam and proceed to Examset Creation.',
    },
];
