import { FormControlLabel, FormLabel, RadioGroup as RG, Radio, FormControl, } from '@mui/material';
import React from 'react';
import { useStyle } from '../../styles/style';
const RadioGroup = (props) => {
    const classes = useStyle();
    return (React.createElement(FormControl, null,
        (props === null || props === void 0 ? void 0 : props.parentLabel) && (React.createElement(FormLabel, { classes: { root: classes.formLabel } }, props.parentLabel)),
        React.createElement(RG, { onChange: props.onChange, defaultValue: props.defaultValue, row: props.row }, props.labels.map((label) => (React.createElement(FormControlLabel, { control: React.createElement(Radio, { size: "small" }), label: label, key: label, value: label, disabled: props.disabled }))))));
};
export default RadioGroup;
