/* eslint-disable consistent-return */
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Button, IconButton, InputAdornment, TextField, Typography, } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useStyle } from '../../styles/style';
const ExamsHeader = ({ setSearch }) => {
    const classes = useStyle();
    const [expanded, setExpanded] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const handleMouseEnter = () => {
        setExpanded(true);
    };
    const handleMouseLeave = () => {
        if (!isFocused) {
            setExpanded(false);
        }
    };
    const handleFocus = () => {
        setIsFocused(true);
    };
    const handleBlur = () => {
        setIsFocused(false);
        setExpanded(false);
    };
    return (React.createElement("div", { className: classes.examHeader },
        React.createElement(Typography, { variant: "body2" }, "Exam List"),
        React.createElement("div", null,
            React.createElement(TextField, { placeholder: "Search", size: "small", id: "examSearch", className: expanded ? classes.searchExpand : classes.searchCollapse, onFocus: handleFocus, onBlur: handleBlur, onMouseEnter: () => handleMouseEnter(), onMouseLeave: () => handleMouseLeave(), onChange: (e) => setSearch(e.target.value), InputProps: {
                    startAdornment: (React.createElement(InputAdornment, { position: "start" },
                        React.createElement(IconButton, { className: classes.searchIcon },
                            React.createElement(SearchIcon, null)))),
                } }),
            React.createElement(Link, { to: "add" },
                React.createElement(Button, { variant: "contained", size: "small", startIcon: React.createElement(AddIcon, null), color: "primary", style: {
                        width: '64px',
                        height: '28px',
                    } }, "Add")))));
};
export default ExamsHeader;
