import { Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postStudentStatus } from '../../store/actions';
const StudentMapping = () => {
    const dispatch = useDispatch();
    const loader = useSelector((state) => state.exam.loader);
    const studentTenantMapping = useSelector((state) => state.exam.studentTenantMapping);
    const handleClick = (event, cellValues) => {
        dispatch(postStudentStatus({ uniqueID: cellValues.row.id, status: 'APPROVED' }));
    };
    const columns = [
        { field: 'id' },
        { field: 'studentName', headerName: 'Student Name', width: 150 },
        { field: 'studentEmail', headerName: 'Student Email', width: 240 },
        { field: 'registeredOn', headerName: 'Registered On', width: 240 },
        { field: 'status', headerName: 'Status', width: 240 },
        {
            field: 'Approve',
            renderCell: (cellValues) => (React.createElement(Button, { variant: "contained", color: "primary", onClick: (event) => {
                    handleClick(event, cellValues);
                }, disabled: cellValues.row.status !== 'APPROVAL PENDING' }, "Approve")),
        },
    ];
    const rows = studentTenantMapping === null || studentTenantMapping === void 0 ? void 0 : studentTenantMapping.map((map) => {
        const e = {
            id: map.StudentTenantMappings[0].uniqueID,
            studentName: String(map.firstName).concat(' ').concat(map.lastName),
            studentEmail: map.emailId,
            registeredOn: new Date(map.createdDate).toLocaleDateString(),
            status: map.StudentTenantMappings[0].status === 'Pending_Approval'
                ? 'APPROVAL PENDING'
                : map.StudentTenantMappings[0].status,
        };
        return e;
    });
    return (React.createElement("div", { style: { height: 600, width: '100%' } },
        React.createElement(DataGrid, { rows: rows, columns: columns, loading: loader.studentTenantMapping, columnVisibilityModel: {
                id: false,
            }, disableColumnMenu: true })));
};
export default StudentMapping;
