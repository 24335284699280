/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Snackbar } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from '../../store/actions';
const CustomSnackbar = () => {
    const dispatch = useDispatch();
    const { snackbarOpen, snackbarMsg, snackbarType } = useSelector((state) => state.exam.snackbar);
    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(setSnackbar({ snackbarOpen: false, snackbarType, snackbarMsg }));
    };
    return (React.createElement("div", null,
        React.createElement(Snackbar, { anchorOrigin: { vertical: 'top', horizontal: 'right' }, open: snackbarOpen, autoHideDuration: 3000, onClose: handleClose },
            React.createElement(Alert, { elevation: 6, variant: "filled", onClose: handleClose, color: snackbarType, severity: snackbarType }, snackbarMsg))));
};
export default CustomSnackbar;
