import React from 'react';
import { useParams } from 'react-router-dom';
import ExamForm from '.';
import { booleanToString } from '../../utils/commonUtils';
import { useGetExamById } from '../../utils/hookUtils';
const ModifyViewForm = (props) => {
    const { view } = props;
    const { examID } = useParams();
    if (!examID)
        throw Error('ExamID not found');
    const exam = useGetExamById(examID);
    const values = {
        examName: exam.examName,
        examDescription: exam.examDescription,
        disclosure: exam.disclosure,
        totalExamPrice: exam.totalExamPrice,
        noOfQuestionsPerSet: exam.noOfQuestionsPerSet,
        correctAnswerMark: exam.correctAnswerMark,
        negativeAnswerMark: exam.negativeAnswerMark,
        durationInMinute: exam.durationInMinute,
        noOfSets: exam.noOfSets,
        negativeMarkingAllowed: booleanToString(exam.negativeMarkingAllowed),
    };
    return React.createElement(ExamForm, { values: values, view: view, update: true });
};
export default ModifyViewForm;
