/* eslint-disable @typescript-eslint/no-explicit-any */
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import './App.css';
import CustomSnackbar from './components/BaseComponents/Snackbar';
import TopLoader from './components/BaseComponents/TopLoader';
import RouteProvider from './router';
import { postPageAudit, postTeacherLoginSuccess } from './store/actions';
import { getDecompressedData } from './utils/compressionUtils';
function App() {
    const dispatch = useDispatch();
    let user = Cookies.get('user');
    user = user && JSON.parse(getDecompressedData(user !== null && user !== void 0 ? user : ''));
    if (user === null || user === void 0 ? void 0 : user.tenantID)
        dispatch(postTeacherLoginSuccess(user));
    useEffect(() => {
        var _a;
        Cookies.set('sessionID', uuid());
        dispatch(postPageAudit({ page: 'TEACHER PORTAL:landing' }));
        if (!user &&
            window.location.href !== process.env.REACT_APP_TEACHER_PORTAL_URL &&
            window.location.pathname !== '/forgotpassword')
            window.location.assign((_a = process.env.REACT_APP_TEACHER_PORTAL_URL) !== null && _a !== void 0 ? _a : '');
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(TopLoader, null),
        React.createElement(CustomSnackbar, null),
        React.createElement(RouteProvider, null)));
}
export default App;
